const today = new Date();
const currentYear = today.getFullYear();
const loginYear = 2021;

export const usersModule = {
  labelsList: [
    { id: 1, name: "Banking", slug: "banking", color: "#FF8C00" },
    { id: 2, name: "Company", slug: "company", color: "#00C4B4" },
    { id: 3, name: "Payments", slug: "payments", color: "#0F9AF7" }
  ],
  usersList: [
    {
      id: 1457690400,
      name: "Alex Dolgove",
      profile_pic: "https://via.placeholder.com/150",
      email: "alex-dolgove@example.com",
      emailUsage: 1.11, // GB
      phones: [{ phone: "(215)-659-7529", label: "Home" }],
      designation: "CEO",
      starred: false,
      company: "TCS",
      labels: [2],
      status: "active",
      suspendedBy: "Admin",
      suspendedAt: new Date(),
      lastLoginAt: new Date(loginYear, 1, 1),
      createdAt: new Date(currentYear, 1, 1),
      updatedAt: new Date(currentYear, 1, 1)
    },
    {
      id: 1457690401,
      name: "Chelsea Johns",
      profile_pic: "https://via.placeholder.com/150",
      email: "chelsea-johnss@example.com",
      emailUsage: 1.6, // GB
      phones: [{ phone: "(215)-659-7529", label: "home" }],
      designation: "CFO",
      starred: false,
      company: "Infosys",
      labels: [2, 1],
      status: "suspended",
      suspendedBy: "Admin",
      suspendedAt: new Date(currentYear, 1, 1),
      lastLoginAt: new Date(loginYear, 2, 17),
      createdAt: new Date(currentYear, 1, 1),
      updatedAt: new Date(currentYear, 1, 1)
    },
    {
      id: 1457690402,
      name: "Domnic Brown",
      profile_pic: "https://via.placeholder.com/150",
      email: "domnic-brown@example.com",
      emailUsage: 1.8, // GB
      phones: [{ phone: "(215)-748-7855", label: "home" }],
      designation: "Designer",
      starred: false,
      company: "Accenture",
      labels: [3],
      status: "active",
      suspendedBy: "Admin",
      suspendedAt: new Date(),
      lastLoginAt: new Date(loginYear, 6, 6),
      createdAt: new Date(currentYear, 1, 1),
      updatedAt: new Date(currentYear, 1, 1)
    },
    {
      id: 1457690403,
      name: "Domnic Harris",
      profile_pic: "https://via.placeholder.com/150",
      email: "domnic-brown@example.com",
      emailUsage: 1.7, // GB
      phones: [{ phone: "(215)-659-7529", label: "home" }],
      designation: "PHP Developer",
      starred: false,
      company: "Pizza Hut",
      labels: [],
      status: "active",
      suspendedBy: "Admin",
      suspendedAt: new Date(),
      lastLoginAt: new Date(loginYear, 5, 20),
      createdAt: new Date(currentYear, 1, 1),
      updatedAt: new Date(currentYear, 1, 1)
    },
    {
      id: 1457690404,
      name: "Garry Sobars",
      profile_pic: "https://via.placeholder.com/150",
      email: "garry-sobars@example.com",
      emailUsage: 2.4, // GB
      phones: [{ phone: "(215)-659-8965", label: "home" }],
      designation: "HR Manager",
      starred: true,
      company: "Dominos",
      labels: [],
      status: "suspended",
      suspendedBy: "Admin",
      suspendedAt: new Date(currentYear, 1, 1),
      lastLoginAt: new Date(loginYear, 5, 30),
      createdAt: new Date(currentYear, 1, 1),
      updatedAt: new Date(currentYear, 1, 1)
    },
    {
      id: 1457690405,
      name: "Jeson Born",
      profile_pic: "https://via.placeholder.com/150",
      email: "jeson-born@example.com",
      emailUsage: 0.4, // GB
      phones: [{ phone: "(215)-876-5434", label: "home" }],
      designation: "Marketing Head",
      starred: false,
      company: "Subway",
      labels: [],
      status: "active",
      suspendedBy: "Admin",
      suspendedAt: new Date(),
      lastLoginAt: new Date(loginYear, 6, 5),
      createdAt: new Date(currentYear, 1, 1),
      updatedAt: new Date(currentYear, 1, 1)
    },
    {
      id: 1457690406,
      name: "Jimmy Jo",
      profile_pic: "https://via.placeholder.com/150g",
      email: "jimmy-jo@example.com",
      emailUsage: 0.9, // GB
      phones: [{ phone: "(215)-659-7529", label: "home" }],
      designation: "BDO",
      starred: true,
      company: "Honda",
      labels: [],
      status: "active",
      suspendedBy: "Admin",
      suspendedAt: new Date(),
      lastLoginAt: new Date(loginYear, 4, 12),
      createdAt: new Date(currentYear, 1, 1),
      updatedAt: new Date(currentYear, 1, 1)
    },
    {
      id: 1457690407,
      name: "John Smith",
      profile_pic: "https://via.placeholder.com/150",
      email: "john-smith@example.com",
      emailUsage: 1.1, // GB
      phones: [{ phone: "(215)-456-2346", label: "home" }],
      designation: "CCO",
      starred: false,
      company: "TVS",
      labels: [],
      status: "active",
      suspendedBy: "Admin",
      suspendedAt: new Date(),
      lastLoginAt: new Date(loginYear, 4, 16),
      createdAt: new Date(currentYear, 1, 1),
      updatedAt: new Date(currentYear, 1, 1)
    },
    {
      id: 1457690408,
      name: "Jonathan Modano",
      profile_pic: "https://via.placeholder.com/150",
      email: "jonathan-modano@example.com",
      emailUsage: 2.2, // GB
      phones: [{ phone: "(215)-278-4357", label: "home" }],
      designation: "Developer",
      starred: true,
      company: "Hero",
      labels: [],
      status: "active",
      suspendedBy: "Admin",
      suspendedAt: new Date(),
      lastLoginAt: new Date(loginYear, 3, 21),
      createdAt: new Date(currentYear, 1, 1),
      updatedAt: new Date(currentYear, 1, 1)
    },
    {
      id: 1457690409,
      name: "Kadir M",
      profile_pic: "https://via.placeholder.com/150",
      email: "kadir-m@example.com",
      emailUsage: 1.2, // GB
      phones: [{ phone: "(215)-286-0788", label: "home" }],
      designation: "UI-UX Designer",
      starred: false,
      company: "Hyundai",
      labels: [],
      status: "active",
      suspendedBy: "Admin",
      suspendedAt: new Date(),
      lastLoginAt: new Date(loginYear, 3, 19),
      createdAt: new Date(currentYear, 1, 1),
      updatedAt: new Date(currentYear, 1, 1)
    },
    {
      id: 1457690410,
      name: "Ken Ramirez",
      profile_pic: "https://via.placeholder.com/150",
      email: "ken-remirez@example.com",
      emailUsage: 3.1, // GB
      phones: [{ phone: "(215)-586-4676", label: "home" }],
      designation: "CEO",
      starred: false,
      company: "Maruti",
      labels: [],
      status: "active",
      suspendedBy: "Admin",
      suspendedAt: new Date(),
      lastLoginAt: new Date(loginYear, 5, 11),
      createdAt: new Date(currentYear, 1, 1),
      updatedAt: new Date(currentYear, 1, 1)
    },
    {
      id: 1457690500,
      name: "Michael Dogov",
      profile_pic: "https://via.placeholder.com/150",
      email: "michael-dogov@example.com",
      emailUsage: 2.5, // GB
      phones: [{ phone: "(215)-659-7529", label: "home" }],
      designation: "CEO",
      starred: false,
      company: "Chevrolet",
      labels: [],
      status: "suspended",
      suspendedBy: "Admin",
      suspendedAt: new Date(currentYear, 1, 1),
      lastLoginAt: new Date(loginYear, 2, 21),
      createdAt: new Date(currentYear, 1, 1),
      updatedAt: new Date(currentYear, 1, 1)
    },
    {
      id: 1457690501,
      name: "Stella Johnson",
      profile_pic: "https://via.placeholder.com/150",
      email: "stella-johnson@example.com",
      emailUsage: 2.3, // GB
      phones: [{ phone: "(215)-745-2345", label: "home" }],
      designation: "CFO",
      starred: false,
      company: "Morgan Garrage",
      labels: [],
      status: "active",
      suspendedBy: "Admin",
      suspendedAt: new Date(),
      lastLoginAt: new Date(loginYear, 3, 13),
      createdAt: new Date(currentYear, 1, 1),
      updatedAt: new Date(currentYear, 1, 1)
    },
    {
      id: 1457690502,
      name: "Steve Smith",
      profile_pic: "https://via.placeholder.com/150",
      email: "steve-smith@example.com",
      emailUsage: 2.6, // GB
      phones: [{ phone: "(215)-748-3265", label: "home" }],
      designation: "Designer",
      starred: false,
      company: "Tata",
      labels: [],
      status: "active",
      suspendedBy: "Admin",
      suspendedAt: new Date(),
      lastLoginAt: new Date(loginYear, 2, 23),
      createdAt: new Date(currentYear, 1, 1),
      updatedAt: new Date(currentYear, 1, 1)
    },
    {
      id: 1457690503,
      name: "Noah Owens",
      profile_pic: "https://via.placeholder.com/150",
      email: "noah-owens@example.com",
      emailUsage: 1.6, // GB
      phones: [{ phone: "(215)-756-3465", label: "home" }],
      designation: "PHP Developer",
      starred: false,
      Company: "Levis",
      labels: [],
      status: "active",
      suspendedBy: "Admin",
      suspendedAt: new Date(),
      lastLoginAt: new Date(loginYear, 3, 9),
      createdAt: new Date(currentYear, 1, 1),
      updatedAt: new Date(currentYear, 1, 1)
    },
    {
      id: 1457690404,
      name: "Ellen Manning",
      profile_pic: "",
      email: "ellen-manning@example.com",
      emailUsage: 1.6, // GB
      phones: [{ phone: "(215)-659-3246", label: "home" }],
      designation: "HR Manager",
      starred: true,
      company: "John Players",
      labels: [],
      status: "active",
      suspendedBy: "Admin",
      suspendedAt: new Date(),
      lastLoginAt: new Date(loginYear, 2, 26),
      createdAt: new Date(currentYear, 1, 1),
      updatedAt: new Date(currentYear, 1, 1)
    },
    {
      id: 1457690505,
      name: "Angel Jones",
      profile_pic: "",
      email: "angel-jones@example.com",
      emailUsage: 0.8, // GB
      phones: [{ phone: "(215)-876-3246", label: "home" }],
      designation: "Marketing Head",
      starred: false,
      company: "Jaguar",
      labels: [],
      status: "active",
      suspendedBy: "Admin",
      suspendedAt: new Date(),
      lastLoginAt: new Date(loginYear, 5, 18),
      createdAt: new Date(currentYear, 1, 1),
      updatedAt: new Date(currentYear, 1, 1)
    },
    {
      id: 1457690506,
      name: "Dollie Norton",
      profile_pic: "",
      email: "dollie-norton@example.com",
      emailUsage: 0.7, // GB
      phones: [{ phone: "(215)-785-0967", label: "home" }],
      designation: "BDO",
      starred: true,
      company: "Reliance",
      labels: [],
      status: "active",
      suspendedBy: "Admin",
      suspendedAt: new Date(),
      lastLoginAt: new Date(loginYear, 6, 2),
      createdAt: new Date(currentYear, 1, 1),
      updatedAt: new Date(currentYear, 1, 1)
    },
    {
      id: 1457690507,
      name: "Joshua Brian",
      profile_pic: "",
      email: "joshua-brian@example.com",
      emailUsage: 0.6, // GB
      phones: [{ phone: "(215)-456-0677", label: "home" }],
      designation: "CCO",
      starred: false,
      company: "Flipkart",
      labels: [],
      status: "active",
      suspendedBy: "Admin",
      suspendedAt: new Date(),
      lastLoginAt: new Date(loginYear, 6, 1),
      createdAt: new Date(currentYear, 1, 1),
      updatedAt: new Date(currentYear, 1, 1)
    },
    {
      id: 1457690508,
      name: "Amay Mathur",
      profile_pic: "",
      email: "amay-mathur@example.com",
      emailUsage: 2.1, // GB
      phones: [{ phone: "(215)-278-5475", label: "home" }],
      designation: "Developer",
      starred: true,
      company: "Snapdeal",
      labels: [],
      status: "active",
      suspendedBy: "Admin",
      suspendedAt: new Date(),
      lastLoginAt: new Date(loginYear, 6, 4),
      createdAt: new Date(currentYear, 1, 1),
      updatedAt: new Date(currentYear, 1, 1)
    },
    {
      id: 1457690509,
      name: "Chris Mathew",
      profile_pic: "",
      email: "chris-mathew@example.com",
      emailUsage: 1.2, // GB
      phones: [{ phone: "(215)-286-0456", label: "home" }],
      designation: "UI-UX Designer",
      starred: false,
      company: "Amazon",
      labels: [],
      status: "active",
      suspendedBy: "Admin",
      suspendedAt: new Date(),
      lastLoginAt: new Date(loginYear, 5, 28),
      createdAt: new Date(currentYear, 1, 1),
      updatedAt: new Date(currentYear, 1, 1)
    },
    {
      id: 1457690510,
      name: "Nikki Johnson",
      profile_pic: "",
      email: "nikki-johnson@example.com",
      emailUsage: 0.3, // GB
      phones: [{ phone: "(215)-586-2355", label: "home" }],
      designation: "CEO",
      starred: false,
      company: "Myntra",
      labels: [],
      status: "active",
      suspendedBy: "Admin",
      suspendedAt: new Date(),
      lastLoginAt: new Date(loginYear, 1, 14),
      createdAt: new Date(currentYear, 1, 1),
      updatedAt: new Date(currentYear, 1, 1)
    }
  ]
};
