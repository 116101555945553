import { lighten, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    "& .Mui-error": {
      color: "red"
    },
    "& .MuiFormHelperText-root": {
      color: "red"
    }
  },
  titleRoot2: {
    fontWeight: "600",
    marginLeft: "30px",
    whiteSpace: "nowrap",
    "&.MuiTypography-body1": {
      fontSize: 16
    }
  },
  imageButton: {
    fontSize: "10px",
    marginLeft: "5px",
    color: theme.palette.text.primary,
    textOverflow: "ellipsis",
    maxWidth: "200px",
    display: "block",
    overflow: "hidden",
    whiteSpace: "nowrap"
  },
  imageButtonWrapper: {
    marginBottom: "10px",
    marginTop: "5px",
    marginLeft: "7px"
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(4),
    backgroundColor: lighten(theme.palette.background.paper, 0.1)
  },
  container: {
    maxHeight: 415
  },
  table: {
    minWidth: 750,
    "& .MuiTableCell-head": {
      fontWeight: 600
    }
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.common.dark,
    textOverflow: "ellipsis",
    maxWidth: "150px",
    display: "block",
    overflow: "hidden",
    whiteSpace: "nowrap"
  },
  tableHeadRowCellRoot: {
    fontSize: 14,
    fontWeight: 600,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 16,
    letterSpacing: 0.4,
    textAlign: "center",
    color: theme.palette.common.dark,
    borderBottom: "0 none",
    "&:first-child": {
      paddingLeft: 24
    },
    "&:last-child": {
      paddingRight: 24
    }
  },
  tableRowCellRoot: {
    fontSize: 14,
    fontWeight: 300,
    // letterSpacing: 0.25,
    // color: theme.palette.common.dark,
    textOverflow: "ellipsis",
    maxWidth: "150px",
    // display: 'block',
    overflow: "hidden",
    whiteSpace: "nowrap",
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 16,
    letterSpacing: 0.4,
    textAlign: "center",
    color: theme.palette.common.dark,
    borderBottom: "0 none",
    "&:first-child": {
      paddingLeft: 24
    },
    "&:last-child": {
      paddingRight: 24
    }
  },
  tableCellRoot: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    fontSize: 14,
    letterSpacing: 0.25,
    textAlign: "center",
    color: theme.palette.text.primary,
    whiteSpace: "nowrap",
    borderBottom: "0 none",
    "&:first-child": {
      paddingLeft: 24,
      textAlign: "left"
    },

    "& .Cmt-avatar-more": {
      fontSize: 10,
      color: theme.palette.primary.main
    }
  },
  scrollbarRoot: {
    height: 300
  },
  itemRoot: {
    maxHeight: "400px",
    overflow: "auto"
  },
  assignServiceType: {
    minHeight: "40px",
    minWidth: "350px",
    fontSize: "18px",
    "& .chip": {
      background: `${theme.palette.primary.main} !important`
    },
    "& .highlightOption": {
      background: `${theme.palette.primary.main} !important`
    },
    "& .multiSelectContainer li": {
      "&:hover": {
        background: `${theme.palette.primary.main} !important`
      }
    },
    "& li": {
      fontSize: "14px",
      "&:hover": {
        background: `${theme.palette.primary.main} !important`
      }
    },
    "& input": {
      fontSize: "16px",
      paddingLeft: "5px"
    }
  },
  serviceTypeView: {
    zIndex: 99,
    minHeight: "40px",
    minWidth: "200px",
    fontSize: "14px",
    "& .chip": {
      background: `${theme.palette.primary.main} !important`
    },
    "& .highlightOption": {
      background: `${theme.palette.primary.main} !important`
    },
    "& .multiSelectContainer li": {
      "&:hover": {
        background: `${theme.palette.primary.main} !important`
      }
    },
    "& li": {
      fontSize: "14px",
      "&:hover": {
        background: `${theme.palette.primary.main} !important`
      }
    },
    "& input": {
      fontSize: "16px",
      paddingLeft: "5px"
    },
    "& .optionContainer": {
      border: "1px solid #ccc",
      borderRadius: "4px",
      display: "block",
      margin: 0,
      maxHeight: "125px",
      overflowY: "auto",
      padding: 0
    }
  },
  assetTypeView: {
    zIndex: 70,
    minHeight: "40px",
    minWidth: "200px",
    fontSize: "14px",
    "& .chip": {
      background: `${theme.palette.primary.main} !important`
    },
    "& .highlightOption": {
      background: `${theme.palette.primary.main} !important`
    },
    "& .multiSelectContainer li": {
      "&:hover": {
        background: `${theme.palette.primary.main} !important`
      }
    },
    "& li": {
      fontSize: "14px",
      "&:hover": {
        background: `${theme.palette.primary.main} !important`
      }
    },
    "& input": {
      fontSize: "16px",
      paddingLeft: "5px"
    },
    "& .optionContainer": {
      border: "1px solid #ccc",
      borderRadius: "4px",
      display: "block",
      margin: 0,
      maxHeight: "125px",
      overflowY: "auto",
      padding: 0
    }
  },
  errText: {
    color: "red",
    marginLeft: "14px",
    marginRight: "14px",
    fontSize: "0.75rem",
    marginTop: "3px",
    textAlign: "left",
    fontFamily: "Normal",
    fontWeight: 400,
    lineHeight: 1.66
  }
}));

export default useStyles;
