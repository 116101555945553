import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import EmailIcon from "@material-ui/icons/Email";
// import { CheckCircleOutline } from '@material-ui/icons';
import { Divider } from "@material-ui/core";
import GridContainer from "@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import BuildIcon from "@material-ui/icons/Build";
import PageContainer from "@jumbo/components/PageComponents/layouts/PageContainer";
import { useParams } from "react-router";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Button } from "@material-ui/core";
import RateReviewIcon from "@material-ui/icons/RateReview";
import DescriptionIcon from "@material-ui/icons/Description";
import moment from "moment";
import ImageIcon from "@material-ui/icons/Image";
import ConfirmDialogue from "./ConfirmDialogue";
import DisputeDialogue from "./DisputeDialogue";
import Rating from "@material-ui/lab/Rating";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ConfirmDialog from "@jumbo/components/Common/ConfirmDialog";
import { funResolveDispute, getTenantDetails } from "redux/actions/OpenRequest";
import useStyles from "./index.style";
import AlarmOnIcon from "@material-ui/icons/AlarmOn";
import ScheduleIcon from "@material-ui/icons/Schedule";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import VerticalSplitIcon from "@material-ui/icons/VerticalSplit";
import LaunchIcon from "@material-ui/icons/Launch";
import FaceIcon from "@material-ui/icons/Face";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import { Block, CheckCircleOutline } from "@material-ui/icons";
import EmojiPeopleIcon from "@material-ui/icons/EmojiPeople";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import BugReportIcon from "@material-ui/icons/BugReport";
import { setCurrentCallServiceRequest } from "redux/actions/CallCenterServiceRequest";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import EventIcon from "@material-ui/icons/Event";

const breadcrumbs = [
  { label: "Home", link: "/" },
  { label: "All Requests", link: "/all-requests" },
  { label: "All Requests Details", isActive: true }
];

const UserDetailView = ({ open, onCloseDialog }) => {
  const classes = useStyles();
  let { id } = useParams();
  const dispatch = useDispatch();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openDisputeDialog, setOpenDisputeDialog] = useState(false);
  const [openResolveDisputeDialog, setOpenResolveDisputeDialog] = useState(
    false
  );
  const [value, setValue] = React.useState(0);
  const { details, asset_details } = useSelector(
    ({ OPENREQUESTMANAGEMENT }) => OPENREQUESTMANAGEMENT
  );

  useEffect(() => {
    dispatch(setCurrentCallServiceRequest(null)); //added to clear create request after switching route
    dispatch(getTenantDetails(id));
  }, []);

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };

  const handleCloseDisputeDialog = () => {
    setOpenDisputeDialog(false);
  };

  const handleConfirmResolveDisputeDialog = () => {
    setOpenResolveDisputeDialog(false);
    const userDetail = {
      _id: details?._id
    };
    dispatch(
      funResolveDispute(userDetail, details?._id, () => {
        dispatch(getTenantDetails(id));
      })
    );
  };

  const handleCancelResolveDisputeDialog = () => {
    setOpenResolveDisputeDialog(false);
  };

  return (
    <>
      <PageContainer
        heading="Service Request Details"
        breadcrumbs={breadcrumbs}
      >
        <Paper className={classes.root}>
          <Box px={6} py={5}>
            <GridContainer>
              <Grid item xs={6} sm={6}>
                <Box
                  display="flex"
                  alignItems="center"
                  mb={{ xs: 4, sm: 7 }}
                  mt={5}
                >
                  <BuildIcon />
                  <Box ml={5} display="flex">
                    <Typography className={classes.titleRoot1}>
                      Service Type :
                    </Typography>
                    <Typography className={classes.titleRoot2}>
                      {details?.service_type_details?.name}
                    </Typography>
                  </Box>
                </Box>

                <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
                  <EventIcon />
                  <Box ml={5} display="flex">
                    <Typography className={classes.titleRoot1}>
                      Request Date-Time :
                    </Typography>
                    <Typography className={classes.titleRoot2}>
                      {moment(details?.created_at * 1000).format(
                        "hh:mm A DD/MM/YYYY"
                      )}
                    </Typography>
                  </Box>
                </Box>

                <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
                  <VerticalSplitIcon />
                  <Box ml={5} display="flex">
                    <Typography className={classes.titleRoot1}>
                      Asset Type / Problem Area :
                    </Typography>
                    <Typography className={classes.titleRoot2}>
                      {details?.asset_type_details?.name}
                    </Typography>
                  </Box>
                </Box>

                <Box display="flex" mb={{ xs: 4, sm: 7 }}>
                  <BusinessCenterIcon />
                  <Box ml={5} display="flex">
                    <Typography className={classes.titleRoot1}>
                      Request Type :
                    </Typography>
                    <Typography className={classes.titleRoot2}>
                      {details?.request_type === 1 ? "Planned" : "Reactive"}
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={6} sm={6}>
                <Box display="flex" mb={{ xs: 4, sm: 7 }} mt={5}>
                  <DescriptionIcon />
                  <Box ml={5}>
                    <Typography className={classes.titleRoot1}>
                      Description :
                    </Typography>
                  </Box>
                  <Box>
                    <Typography className={classes.DescriptionNote}>
                      {details?.description}
                    </Typography>
                  </Box>
                </Box>

                <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
                  <BugReportIcon />
                  <Box ml={5} display="flex">
                    <Typography className={classes.titleRoot1}>
                      Problem Details :
                    </Typography>
                    {details?.issue_details?.name ? (
                      <Typography className={classes.titleRoot2}>
                        {details?.issue_details?.name}
                      </Typography>
                    ) : (
                      <Typography className={classes.titleRoot2}>
                        {"-"}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Grid>
            </GridContainer>
          </Box>

          <Box
            style={{ marginLeft: "25px" }}
            mb={{ xs: 4, sm: 7 }}
            component="h3"
          >
            Assets :
          </Box>
          <Box style={{ marginLeft: "30px", marginRight: "30px" }}>
            <TableContainer component={Paper}>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableTitleCell}>
                      Asset Name
                    </TableCell>
                    <TableCell className={classes.tableTitleCell}>
                      Floor
                    </TableCell>
                    <TableCell className={classes.tableTitleCell}>
                      Department
                    </TableCell>
                    <TableCell className={classes.tableTitleCell}>ID</TableCell>
                    <TableCell className={classes.tableTitleCell}>
                      Brand
                    </TableCell>
                    <TableCell className={classes.tableTitleCell}>
                      Model
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  <TableRow className={classes.tableRow}>
                    <TableCell className={classes.tableDataCell}>
                      {asset_details?.name}
                    </TableCell>
                    <TableCell className={classes.tableDataCell}>
                      {asset_details?.floor}
                    </TableCell>
                    <TableCell className={classes.tableDataCell}>
                      {asset_details?.department}
                    </TableCell>
                    <TableCell className={classes.tableDataCell}>
                      {asset_details?.asset_id}
                    </TableCell>
                    <TableCell className={classes.tableDataCell}>
                      {asset_details?.brand}
                    </TableCell>
                    <TableCell className={classes.tableDataCell}>
                      {asset_details?.model}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          {/* <Grid item xs={12} sm={12}> */}
          <Box
            ml={5}
            mt={8}
            display="flex"
            alignItems="top"
            mb={{ xs: 4, sm: 7 }}
          >
            <ImageIcon />
            <Box ml={5} display="flex">
              <Typography className={classes.titleRoot1}>Images :</Typography>
              <Typography>
                <GridContainer>
                  {!details?.service_request_image.length ? (
                    <Grid item xs={12} sm={12}>
                      <Box ml={2}> - </Box>
                    </Grid>
                  ) : details?.service_request_image.length === 1 ? (
                    details?.service_request_image?.map((item, index) => (
                      <Grid item xs={12} sm={12}>
                        <img
                          src={item}
                          key={index}
                          width="150"
                          style={{ marginLeft: "2%", marginRight: "2%" }}
                        />
                      </Grid>
                    ))
                  ) : details?.service_request_image.length === 2 ? (
                    details?.service_request_image?.map((item, index) => (
                      <Grid item xs={6} sm={6}>
                        <img
                          src={item}
                          key={index}
                          width="150"
                          style={{ marginLeft: "2%", marginRight: "2%" }}
                        />
                      </Grid>
                    ))
                  ) : details?.service_request_image.length === 3 ? (
                    details?.service_request_image?.map((item, index) => (
                      <Grid item xs={4} sm={4}>
                        <img
                          src={item}
                          key={index}
                          width="150"
                          style={{ marginLeft: "2%", marginRight: "2%" }}
                        />
                      </Grid>
                    ))
                  ) : (
                    details?.service_request_image?.map((item, index) => (
                      <Grid item xs={3} sm={3}>
                        <img
                          src={item}
                          key={index}
                          height="100"
                          style={{ marginLeft: "2%" }}
                        />
                      </Grid>
                    ))
                  )}
                </GridContainer>
              </Typography>
            </Box>
          </Box>

          <Divider variant="middle" />

          <Box px={6} py={5}>
            <GridContainer>
              <Grid item xs={6} sm={6}>
                <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
                  <FaceIcon />
                  <Box ml={5} display="flex">
                    <Typography className={classes.titleRoot1}>
                      Vendor :
                    </Typography>
                    {details?.vendor_details?.first_name &&
                    details?.vendor_details?.last_name ? (
                      <Typography className={classes.titleRoot2}>
                        {details?.vendor_details?.first_name +
                          " " +
                          details?.vendor_details?.last_name}
                      </Typography>
                    ) : (
                      <Typography className={classes.titleRoot2}>
                        {"-"}
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
                  <EventAvailableIcon />
                  <Box ml={5} display="flex">
                    <Typography className={classes.titleRoot1}>
                      Request Completion Date-Time :
                    </Typography>
                    <Typography className={classes.titleRoot2}>
                      {"-"}
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={6} sm={6}>
                <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
                  <FaceIcon />
                  <Box ml={5} display="flex">
                    <Typography className={classes.titleRoot1}>
                      Contractor :
                    </Typography>
                    {details?.contractor_details?.first_name &&
                    details?.contractor_details?.last_name ? (
                      <Typography className={classes.titleRoot2}>
                        {details?.contractor_details?.first_name +
                          " " +
                          details?.contractor_details?.last_name}
                      </Typography>
                    ) : (
                      <Typography className={classes.titleRoot2}>
                        {"-"}
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 5 }}>
                  {details?.service_status === 1 ||
                  details?.service_status === 9 ? (
                    <TrendingUpIcon />
                  ) : details?.service_status === 2 ||
                    details?.service_status === 3 ? (
                    <EmojiPeopleIcon />
                  ) : details?.service_status === 5 ? (
                    <Block />
                  ) : (
                    <CheckCircleOutline />
                  )}
                  <Box ml={5} display="flex">
                    <Typography className={classes.titleRoot1}>
                      Status :
                    </Typography>
                    <Typography className={classes.titleRoot2}>
                      {details?.service_status === 1 ||
                      details?.service_status === 9
                        ? "Request Mode"
                        : details?.service_status === 2 ||
                          details?.service_status === 3
                        ? "Vendor Assigned"
                        : details?.service_status === 5
                        ? "Dispute Raised"
                        : "Service Completed"}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </GridContainer>
          </Box>

          <Divider variant="middle" />

          {!details?.ratings?.review && !details?.disputes?.review ? (
            details?.service_status === 4 ||
            details?.service_status === 6 ||
            details?.service_status === 7 ||
            details?.service_status === 8 ? (
              <Typography
                style={{
                  marginLeft: "30px",
                  marginRight: "30px",
                  marginTop: "30px"
                }}
              >
                {
                  "This service request is now been resolved. You can now confirm the request completion & rate the service. You may raise dispute within 7 days if not satisfied with the service."
                }
              </Typography>
            ) : null
          ) : null}

          {!details?.ratings?.review && !details?.disputes?.review ? (
            details?.service_status === 4 ||
            details?.service_status === 6 ||
            details?.service_status === 7 ||
            details?.service_status === 8 ? (
              <Box display="flex" justifyContent="center">
                <Button
                  variant="contained"
                  style={{
                    marginRight: "15px",
                    minWidth: "100px",
                    marginBottom: "20px",
                    marginTop: "30px"
                  }}
                  color="primary"
                  onClick={e => setOpenConfirmDialog(true)}
                >
                  Confirm Completion
                </Button>
                <Button
                  variant="outlined"
                  onClick={e => setOpenDisputeDialog(true)}
                  style={{
                    marginLeft: "15px",
                    minWidth: "100px",
                    marginBottom: "20px",
                    marginTop: "30px"
                  }}
                >
                  Raise Dispute
                </Button>
                <Box ml={2}></Box>
              </Box>
            ) : null
          ) : null}

          {details?.ratings?.review ? (
            <>
              <Box
                style={{ marginTop: "10px", marginLeft: "25px" }}
                component="h3"
              >
                Review :
              </Box>

              <Box px={6} py={5}>
                <GridContainer>
                  <Grid item xs={6} sm={6}>
                    <Box display="flex" alignItems="center">
                      <ThumbUpIcon />
                      <Box ml={5}>
                        <Typography className={classes.titleRoot1}>
                          Rating :
                        </Typography>
                      </Box>
                      <Box>
                        <Rating
                          name="read-only"
                          value={details?.ratings?.rating}
                          readOnly
                        />
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={6} sm={6}>
                    <Box ml={5} display="flex" alignItems="center">
                      <RateReviewIcon />
                      <Box ml={5}>
                        <Typography className={classes.titleRoot1}>
                          Review :{" "}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography className={classes.reviewNote}>
                          {details?.ratings?.review}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <Box
                      ml={5}
                      mt={8}
                      display="flex"
                      alignItems="top"
                      mb={{ xs: 4, sm: 7 }}
                    >
                      {/* <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 5 }}> */}
                      <ImageIcon />
                      <Box ml={5} display="flex">
                        <Box>
                          <Typography className={classes.titleRoot1}>
                            Images :
                          </Typography>
                        </Box>
                        <Box>
                          <Typography>
                            <span>
                              {!details?.ratings?.images?.length
                                ? "-"
                                : details?.ratings?.images?.map(
                                    (item, index) => (
                                      <img
                                        src={item}
                                        key={index}
                                        width="200"
                                        style={{
                                          marginLeft: "2%",
                                          marginRight: "2%"
                                        }}
                                      />
                                    )
                                  )}
                            </span>
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </GridContainer>
              </Box>
            </>
          ) : null}

          {details?.disputes?.review ? (
            <>
              <Box
                style={{ marginTop: "10px", marginLeft: "25px" }}
                component="h3"
              >
                Dispute Raised :
              </Box>

              <Box px={6} py={5}>
                <GridContainer>
                  <Grid item xs={6} sm={6}>
                    <Box
                      display="flex"
                      alignItems="center"
                      mb={{ xs: 4, sm: 7 }}
                    >
                      <RateReviewIcon />
                      <Box ml={5}>
                        <Typography className={classes.titleRoot1}>
                          Review :{" "}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography className={classes.reviewNote}>
                          {details?.disputes?.review}
                        </Typography>
                      </Box>
                    </Box>

                    <Box display="flex" alignItems="top" mb={{ xs: 4, sm: 7 }}>
                      <ImageIcon />
                      <Box ml={5} display="flex">
                        <Typography className={classes.titleRoot1}>
                          Images :
                        </Typography>
                        <Typography>
                          <span>
                            {!details?.disputes?.images?.length
                              ? "-"
                              : details?.disputes?.images?.map(
                                  (item, index) => (
                                    <img
                                      src={item}
                                      key={index}
                                      width="200"
                                      style={{
                                        marginLeft: "2%",
                                        marginRight: "2%"
                                      }}
                                    />
                                  )
                                )}
                          </span>
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>

                  {details?.disputes?.resolved_on ? (
                    <Grid item xs={6} sm={6}>
                      <Box
                        display="flex"
                        alignItems="center"
                        mb={{ xs: 4, sm: 7 }}
                      >
                        <AlarmOnIcon />
                        <Box ml={5}>
                          <Typography className={classes.titleRoot1}>
                            Dispute Resolved On :{" "}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography className={classes.titleRoot2}>
                            {moment(
                              details?.disputes?.resolved_on * 1000
                            ).format("hh:mm A DD/MM/YYYY")}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  ) : null}
                </GridContainer>
              </Box>
            </>
          ) : null}

          {details?.disputes?.review && !details?.disputes?.resolved_on ? (
            <>
              <Box display="flex" justifyContent="center">
                <Button
                  variant="contained"
                  style={{
                    marginRight: "15px",
                    minWidth: "100px",
                    marginBottom: "20px",
                    marginTop: "30px"
                  }}
                  color="primary"
                  onClick={e => setOpenResolveDisputeDialog(true)}
                >
                  Resolve Dispute
                </Button>
              </Box>
            </>
          ) : null}
        </Paper>
      </PageContainer>
      {openConfirmDialog && (
        <ConfirmDialogue
          open={openConfirmDialog}
          onCloseDialog={handleCloseConfirmDialog}
        />
      )}
      {openDisputeDialog && (
        <DisputeDialogue
          open={openDisputeDialog}
          onCloseDialog={handleCloseDisputeDialog}
        />
      )}

      <ConfirmDialog
        open={openResolveDisputeDialog}
        title={`Resolve Dispute`}
        content={"Are you sure, you want to resolve this dispute?"}
        onClose={handleCancelResolveDisputeDialog}
        onConfirm={handleConfirmResolveDisputeDialog}
      />
    </>
  );
};

export default UserDetailView;

UserDetailView.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func
};
