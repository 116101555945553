import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Box } from "@material-ui/core";
import { alpha, makeStyles } from "@material-ui/core/styles";
import CmtCard from "@coremat/CmtCard";
import CmtCardHeader from "@coremat/CmtCard/CmtCardHeader";
import CmtCardContent from "@coremat/CmtCard/CmtCardContent";
import { CurrentAuthMethod } from "@jumbo/constants/AppConstants";
import { useDispatch } from "react-redux";
import { AuhMethods } from "services/auth";
import { requiredMessage } from "@jumbo/constants/ErrorMessages";
import { passwordMisMatch } from "@jumbo/constants/ErrorMessages";

const variant = "default";
const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "50%",
      order: 2
    }
  },
  authContent: {
    padding: 30,
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: props => (props.variant === "default" ? "50%" : "100%"),
      order: 1
    },
    [theme.breakpoints.up("xl")]: {
      padding: 50
    }
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary
  },
  textFieldRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: alpha(theme.palette.common.dark, 0.12)
    },
    "& .Mui-error": {
      color: "red"
    },
    "& .MuiFormHelperText-root": {
      color: "red"
    }
  },
  formcontrolLabelRoot: {
    "& .MuiFormControlLabel-label": {
      [theme.breakpoints.down("xs")]: {
        fontSize: 12
      }
    }
  }
}));

const ChangePassword = ({ method = CurrentAuthMethod }) => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [oldPasswordError, setOldPasswordError] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const classes = useStyles({ variant });
  const dispatch = useDispatch();
  const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$.!%*#?&])[A-Za-z\d@$.!%*#?&]{8,}$/;

  const Validation = () => {
    if (oldPassword === "") {
      setOldPasswordError(requiredMessage);
    }
    if (newPassword === "") {
      setNewPasswordError(requiredMessage);
    } else if (oldPassword === newPassword && oldPassword !== "") {
      setNewPasswordError(
        "Current password and New password are same. Please use different password."
      );
    } else if (!newPassword.match(passwordRegex)) {
      setNewPasswordError(
        "Password must be at least 8 character long and should contain upper case, lower case, symbol and number."
      );
    }
    if (confirmPassword === "") {
      setConfirmPasswordError(requiredMessage);
    } else if (confirmPassword !== newPassword) {
      setConfirmPasswordError(passwordMisMatch);
    }
    if (
      oldPassword !== "" &&
      oldPassword !== newPassword &&
      newPassword.match(passwordRegex) &&
      confirmPassword === newPassword
    ) {
      setConfirmPasswordError("");
      setNewPasswordError("");
      setOldPasswordError("");
      dispatch(
        AuhMethods[method].onChangePassword(
          oldPassword,
          newPassword,
          confirmPassword
        )
      );
      setOldPassword("");
      setNewPassword("");
      setConfirmPassword("");
      setTimeout(() => {
        document.getElementById("oldPassword").value = "";
        document.getElementById("confirmNewPassword").value = "";
        document.getElementById("newPassword").value = "";
      }, 3000);
    }
  };

  const handelonKeyDown = e => {
    if (e.keyCode === 13) {
      Validation();
    }
  };

  return (
    <>
      <CmtCard>
        <CmtCardHeader title="Change Password">
          <Box
            className={classes.textUppercase}
            fontSize={5}
            color="text.secondary"
          ></Box>
        </CmtCardHeader>
        <CmtCardContent>
          <Box mb={2}>
            <TextField
              type="password"
              placeholder="Type Current Password"
              fullWidth
              onChange={event => {
                setOldPassword(event.target.value);
                setOldPasswordError("");
              }}
              helperText={oldPasswordError}
              margin="normal"
              variant="outlined"
              id="oldPassword"
              className={classes.textFieldRoot}
            />
          </Box>
          <Box mb={2}>
            <TextField
              type="password"
              placeholder="Type New Password"
              fullWidth
              onChange={event => {
                setNewPassword(event.target.value);
                setNewPasswordError("");
              }}
              // defaultValue={email}
              helperText={newPasswordError}
              margin="normal"
              variant="outlined"
              id="newPassword"
              className={classes.textFieldRoot}
            />
          </Box>
          <Box mb={2}>
            <TextField
              type="password"
              placeholder="Retype New Password"
              fullWidth
              onChange={event => {
                setConfirmPassword(event.target.value);

                setConfirmPasswordError("");
              }}
              helperText={confirmPasswordError}
              margin="normal"
              variant="outlined"
              id="confirmNewPassword"
              onBlur={event =>
                confirmPassword !== "" && confirmPassword === newPassword
                  ? setConfirmPasswordError("")
                  : null
              }
              onKeyDown={handelonKeyDown}
              className={classes.textFieldRoot}
            />
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Button variant="contained" onClick={Validation} color="primary">
              Save Changes
            </Button>
          </Box>
        </CmtCardContent>
      </CmtCard>
    </>
  );
};

export default ChangePassword;
