import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import { Box } from "@material-ui/core";
import { alpha, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { requiredMessage } from "../../../@jumbo/constants/ErrorMessages";
import CmtCard from "../../../@coremat/CmtCard";
import CmtCardHeader from "../../../@coremat/CmtCard/CmtCardHeader";
import CmtCardContent from "../../../@coremat/CmtCard/CmtCardContent";
import { useDispatch, useSelector } from "react-redux";
import { addNewContactUs } from "../../../redux/actions/ContactUsQuery";
import CallIcon from "@material-ui/icons/Call";
import Typography from "@material-ui/core/Typography";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { setCurrentCallServiceRequest } from "redux/actions/CallCenterServiceRequest";
import { emptySpace } from "@jumbo/constants/ValidationRegex";

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "50%",
      order: 2
    },
    "& .Mui-error": {
      color: "red"
    },
    "& .MuiFormHelperText-root": {
      color: "red"
    }
  },
  authLogo: {
    maxWidth: "70px",
    width: "5rem"
  },
  authContent: {
    padding: 30,
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: props => (props.variant === "default" ? "50%" : "100%"),
      order: 1
    },
    [theme.breakpoints.up("xl")]: {
      padding: 50
    }
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary
  },
  titleRoot1: {
    fontWeight: "600",
    whiteSpace: "nowrap",
    color: theme.palette.text.secondary,
    "&.MuiTypography-body1": {
      fontSize: 14
    }
  },
  titleRoot2: {
    fontWeight: "300",
    marginLeft: "5px",
    fontSize: 14,
    textOverflow: "ellipsis",
    maxWidth: "220px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    color: theme.palette.text.secondary
  },
  textFieldRoot: {
    maxWidth: "500px",
    minWidth: "500px",

    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: alpha(theme.palette.common.dark, 0.12)
    },
    "& .Mui-error": {
      color: "red"
    },
    "& .MuiFormHelperText-root": {
      color: "red"
    }
  },
  formcontrolLabelRoot: {
    "& .MuiFormControlLabel-label": {
      [theme.breakpoints.down("xs")]: {
        fontSize: 12
      }
    }
  }
}));

export default function ContactUs() {
  const classes = useStyles();
  const authUser = useSelector(({ auth }) => auth);
  const [email] = useState(authUser?.authUser?.email);
  const [name] = useState(
    authUser?.authUser?.first_name + " " + authUser?.authUser?.last_name
  );
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [imageError, setImageError] = useState("");
  const [image, setImage] = useState([]);
  const [value, setValue] = React.useState("1");
  const [text, setText] = React.useState(
    "Please provide as much as detail about the service request as possible including service type, asset name, problem details, etc"
  );
  // console.log("value",value)
  const dispatch = useDispatch();
  // console.log('data',image)

  // formData.append('font_color', textColor);

  // const user = {
  //   username: name,
  //   email: email,
  //   query: description,
  //   contactUsImage: image,
  // };
  useEffect(() => {
    dispatch(setCurrentCallServiceRequest(null)); //added to clear create request after switching route
  }, []);
  const handleChange = event => {
    setValue(event.target.value);
    // setText("Please provide as much as detail about the service request as possible including service type, asset name, etc"
    // )
  };

  const handleClear = () => {
    setDescription("");
    setImage("");
    document.getElementById("outlined-multiline-flexible").value = "";
    document.getElementById("image-Filed").value = "";
    setDescriptionError("");
    setImageError("");
  };
  const handelSubmit = () => {
    if (!description || description.match(emptySpace)) {
      setDescriptionError(requiredMessage);
    }
    if (image?.length >= 6) {
      setImageError("Please select image between one to five.");
    }
    if (image) {
      if (description && !description.match(emptySpace) && image?.length < 6) {
        onUserSave();
      }
    } else {
      if (description && !description.match(emptySpace)) {
        onUserSave();
      }
    }
  };
  const onUserSave = () => {
    if (description) {
      const formData = new FormData();

      formData.append("username", name);
      formData.append("email", email);
      formData.append("query", description);
      formData.append("contact_us_type", value);
      console.log("image", image, image?.length < 6, image?.length);
      if (image) {
        console.log("image1", image);
        image.forEach(element => {
          formData.append("contactUsImage", element);
        });
      }

      if (value == 2) {
        dispatch(
          addNewContactUs(formData, true, () => {
            clearData();
          })
        );
      } else {
        dispatch(
          addNewContactUs(formData, false, () => {
            clearData();
          })
        );
      }
    }
  };

  const clearData = () => {
    setValue("1");
    setDescription("");
    setImage("");
    document.getElementById("image-Filed").value = "";
    document.getElementById("outlined-multiline-flexible").value = "";
    setText("");
  };
  return (
    <>
      <CmtCard>
        <CmtCardHeader title="Contact Us">
          <Box
            className={classes.textUppercase}
            fontSize={5}
            color="text.secondary"
          ></Box>
        </CmtCardHeader>
        <CmtCardContent>
          <Box mb={2}>
            <TextField
              label="Name"
              fullWidth
              disabled
              value={name}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
              id="name"
            />
          </Box>
          <Box mb={2}>
            <TextField
              label="Email"
              fullWidth
              disabled
              // onChange={event => setEmail(event.target.value)}
              value={email}
              margin="normal"
              variant="outlined"
              id="email"
              className={classes.textFieldRoot}
            />
          </Box>
          <Box Box mb={2}>
            <TextField
              className={classes.textFieldRoot}
              id="outlined-multiline-flexible"
              label="Description"
              multiline
              minRows={7}
              maxRows={7}
              value={description}
              onChange={event => {
                setDescription(event.target.value);
                setDescriptionError("");
              }}
              variant="outlined"
              helperText={descriptionError}
            />
            {value === "2" ? (
              <Typography style={{ fontSize: 13, marginTop: "5px" }}>
                Note : Please provide as much as details about the service
                request as possible including service type, asset name and
                problem details, etc.
              </Typography>
            ) : null}
          </Box>

          <Box Box mb={6}>
            <Button variant="contained" component="label">
              Image Upload
              <input
                type="file"
                multiple
                id="image-Filed"
                accept="image/png, image/jpeg"
                onChange={e => {
                  setImage([...e.target.files]);
                  setImageError("");
                }}
              />
            </Button>{" "}
            <br />
            <p
              className="MuiFormHelperText-root MuiFormHelperText-contained MuiFormHelperText-marginDense"
              style={{ color: "red", fontSize: 13 }}
            >
              {imageError}
            </p>
            {/* {/ <span >{imageError}</span> /} */}
          </Box>

          <Box display="flex" mb={{ xs: 4, sm: 7 }}>
            <CallIcon />
            <Box ml={5} display="flex">
              <Typography className={classes.titleRoot1}>
                Call Centre Number :
              </Typography>
              <Typography className={classes.titleRoot2}>
                {"9876543210"}
              </Typography>
            </Box>
          </Box>

          <Box display="flex" mb={{ xs: 4, sm: 7 }}>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="query"
                name="query1"
                value={value}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="1"
                  control={<Radio />}
                  label="Ask a Question"
                />
                <FormControlLabel
                  value="2"
                  control={<Radio />}
                  label="Create Service Request"
                />
              </RadioGroup>
            </FormControl>
          </Box>

          <Box display={"flex"}>
            {" "}
            <Box mr={5}>
              <Button variant="outlined" mr={5} onClick={handleClear}>
                {" "}
                Clear
                {/* {/ <IntlMessages id="appModule.signIn" /> /} */}
              </Button>
            </Box>
            <Box>
              <Button
                variant="contained"
                color="primary"
                onClick={handelSubmit}
                ml={5}
              >
                {" "}
                Submit
                {/* {/ <IntlMessages id="appModule.signIn" /> /} */}
              </Button>
            </Box>
          </Box>
        </CmtCardContent>
      </CmtCard>
    </>
  );
}
