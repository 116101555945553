import React from "react";
import { List } from "@material-ui/core";
import NavMenuItem from "./NavMenuItem";
import makeStyles from "@material-ui/core/styles/makeStyles";
import NavSection from "./NavSection";
import NavCollapse from "../Horizontal/NavCollapse";

const useStyles = makeStyles(theme => ({
  sideNavMenu: {
    position: "relative"
  },
  navHeader: {
    position: "relative",
    padding: "24px 16px 20px 16px",
    textTransform: "uppercase",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: 10,
    letterSpacing: 1.5,
    ".Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &": {
      display: "none"
    }
  },
  navSection: {
    position: "relative",
    fontWeight: theme.typography.fontWeightRegular
  }
}));
const CmtVertical = props => {
  const { menuItems } = props;
  const classes = useStyles();

  return (
    <List component="nav" disablePadding className={classes.sideNavMenu}>
      {menuItems.map((item, index) => {
        switch (item.type) {
          case "section":
            return <NavSection {...item} key={index} />;
          case "collapse":
            return <NavCollapse {...item} key={index} />;
          case "item":
            return <NavMenuItem {...item} key={index} />;
          default:
            return null;
        }
      })}
    </List>
  );
};

export default CmtVertical;
