import React from "react";
import { Redirect, Route, Switch } from "react-router";
import Login from "./Auth/Login";
import Signup from "./Auth/Register";
import ForgotPassword from "./Auth/ForgotPassword";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

// coustom page imported here
import Profile from "./Profile/index";
import AboutUs from "./ContentManager/AboutUs/index";
import PrivacyPolicy from "./ContentManager/PrivacyPolicy/index";
import TermsAndConditions from "./ContentManager/TermsAndCondition/index";
import FAQ from "./ContentManager/FAQ/index";
// import LayoutBuilder from './LayoutBuilder';
import ContactUs from "./ContactUsQuery/Contactus";
import FMAdminDemo from "FMAdminDemo";
// import AllRequests from './Modules/AllRequests/index';
import UserDetailView from "./Modules/AllRequestTab/AllRequests/UserDetailView/index";
import OpenRequest from "./Modules/OpenRequest/index";
import AllRequestTab from "./Modules/AllRequestTab/index";
import { project_name } from "../config";
import CreateServiceRequest from "./Modules/CreateServiceRequest/index";
import { Helmet } from "react-helmet";

const RestrictedRoute = ({ component: Component, ...rest }) => {
  const { authUser } = useSelector(({ auth }) => auth);
  return (
    <Route
      {...rest}
      render={(props) =>
        authUser ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/signin",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const Routes = () => {
  const { authUser } = useSelector(({ auth }) => auth);

  const location = useLocation();

  if (location.pathname === "" || location.pathname === "/") {
    return <Redirect to={"/open-requests"} />;
  } else if (authUser && location.pathname === "/signin") {
    return <Redirect to={"/open-requests"} />;
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>{project_name}-Admin Dashboard</title>
        <meta name="title" content={project_name} />
        <meta name="description" content={project_name} />
        <meta name="og:title" content={project_name} />
        <meta name="og:description" content={project_name} />
      </Helmet>
      <Switch>
        <Route path="/signin" component={Login} />
        <Route path="/signup" component={Signup} />
        <Route path="/forgot-password" component={ForgotPassword} />
        {/* coustome routes */}
        <RestrictedRoute path="/profile" component={Profile} />
        <RestrictedRoute path="/contact-us" component={ContactUs} />
        <RestrictedRoute path="/about-us" component={AboutUs} />
        <RestrictedRoute path="/privacy-policy" component={PrivacyPolicy} />
        <RestrictedRoute
          path="/terms-and-conditions"
          component={TermsAndConditions}
        />
        <RestrictedRoute path="/faq" component={FAQ} />
        {/* <RestrictedRoute exact path="/all-requests" component={AllRequests} /> */}
        <RestrictedRoute
          path="/service-request"
          component={CreateServiceRequest}
        />
        <RestrictedRoute
          path="/all-requests/all-requests-details/:id"
          component={UserDetailView}
        />
        <RestrictedRoute path="/open-requests" component={OpenRequest} />
        {/* AllRequestTab */}
        <RestrictedRoute path="/all-requests" component={AllRequestTab} />
        <RestrictedRoute path="*" exact={true} component={FMAdminDemo} />

        {/*<Route path="/layout-builder" component={LayoutBuilder} />*/}
      </Switch>
    </React.Fragment>
  );
};

export default Routes;
