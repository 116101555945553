import React from "react";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PageContainer from "./PageContainer";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  contentArea: {
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      paddingRight: theme.typography.pxToRem(0),
      width: `calc(100% - ${theme.typography.pxToRem(0)})`
    },
    [theme.breakpoints.up("lg")]: {
      paddingRight: theme.typography.pxToRem(54)
    }
  },
  contentSidebar: {
    width: theme.typography.pxToRem(175),
    height: `calc(100vh - ${theme.typography.pxToRem(102)})`,
    position: "sticky",
    top: 30,
    overflowY: "auto",
    flexShrink: 0
  }
}));

const ComponentsDemo = ({ pageTitle, breadcrumbs, children }) => {
  const classes = useStyles();

  return (
    <PageContainer heading={pageTitle} breadcrumbs={breadcrumbs}>
      <Box className={classes.root}>
        <Box className={classes.contentArea}>{children}</Box>
      </Box>
    </PageContainer>
  );
};
export default ComponentsDemo;
