import { fetchError, fetchStart, fetchSuccess } from "./Common";
import axios from "../../services/auth/jwt/config";
import {
  DELETE_BULK_ITEMSLIST,
  GET_ITEMSLIST,
  // SET_TENANT_DETAILS,
  SET_TENANT_DETAILS,
  GET_TENANT_DETAILS,
  GET_ASSETLOCATION,
  GET_ASSET_DETAILS
} from "../../@jumbo/constants/ActionTypes";
import {
  addItems,
  listItems,
  updateItems,
  updateItemsDetails,
  tenantDetails,
  assestDetails,
  confirmCompletion,
  raiseDispute,
  resolveDispute
} from "../../@jumbo/constants/ApiConstatnt";
import { setAuthUser } from "./Auth";
import { history } from "redux/store";

// get Item list
export const getOPenRequestList = (
  sortBy,
  sort,
  searchTerm,
  page,
  limit,
  filterOptions,
  serviceStatus,
  sDate,
  eDate,
  ID,
  resStatus,
  pType,
  callbackFun
) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(listItems, {
        params: {
          sortBy: `${sortBy}:${sort}`,
          search: searchTerm,
          page: page + 1,
          limit: limit,
          status: filterOptions[1],
          service_status: serviceStatus?.join(),
          start_date: sDate,
          end_date: eDate,
          tenant_id: ID,
          request_type: resStatus?.join(),
          priority: pType?.join()
        }
      })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_ITEMSLIST, payload: data.data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError());
          dispatch({ type: GET_ITEMSLIST, payload: [] });
        }
      })
      .catch(error => {
        if (error?.response?.data?.message === "Item list not found.") {
          dispatch({ type: GET_ITEMSLIST, payload: [] });
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};

// set current user for view tab
export const setItemList = user => {
  return dispatch => {
    dispatch({ type: SET_TENANT_DETAILS, payload: user });
  };
};

// add new FM provider
export const addNewItemsList = (user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(addItems, user)
      .then(data => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess("New Part added successfully."));
          }, 2000);
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(
            fetchError("There was something issue in responding server.")
          );
        }
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message));
      });
  };
};

export const updateItemListStatus = (data, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(updateItems, data)
      .then(response => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess("Part status updated successfully."));
          }, 3000);
          if (callbackFun) callbackFun();
        } else {
          dispatch(
            fetchError("There was something issue in responding server.")
          );
        }
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message));
      });
  };
};

export const getTenantDetails = (userId, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(tenantDetails + userId)
      .then(data => {
        if (data.status === 200) {
          dispatch(getAssetDetails(data?.data?.data?.asset_id));
          dispatch(fetchSuccess());
          dispatch({ type: GET_TENANT_DETAILS, payload: data.data.data });

          if (callbackFun) callbackFun();
        } else {
          dispatch(
            fetchError("There was something issue in responding server.")
          );
        }
      })
      .catch(error => {
        if (error?.response?.data?.message === "Tenant Details not found.") {
          dispatch(fetchError(error?.response?.data?.message));
          dispatch({ type: GET_TENANT_DETAILS, payload: [] });
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};

export const setItemListDetails = user => {
  return dispatch => {
    dispatch({ type: SET_TENANT_DETAILS, payload: user });
  };
};

// all function below are not in use currntly
export const deleteBulkUsers = (userIds, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put("/users/bulk-delete", { userIds })
      .then(response => {
        if (response.status === 200) {
          dispatch(fetchSuccess("Selected users were deleted successfully."));
          dispatch({ type: DELETE_BULK_ITEMSLIST, payload: userIds });
          if (callbackFun) callbackFun();
        } else {
          dispatch(
            fetchError("There was something issue in responding server.")
          );
        }
      })
      .catch(error => {
        dispatch(fetchError("There was something issue in responding server"));
      });
  };
};

export const deleteUser = (userId, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .delete("users" + userId)
      .then(data => {
        if (data.status === 200) {
          setTimeout(() => {
            dispatch(fetchSuccess("Part deleted successfully."));
          }, 2000);
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError());
        }
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message));
      });
  };
};

export const sentMailToUser = () => {
  return dispatch => {
    dispatch(fetchSuccess("Email has been sent to user successfully"));
  };
};

export const updateItemList = (user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(updateItemsDetails, user)
      .then(data => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess(" Part updated successfully."));
          }, 3000);
          if (callbackFun) callbackFun();
        } else {
          dispatch(
            fetchError("There was something issue in responding server.")
          );
        }
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message));
      });
  };
};

export const getAssetDetails = (id, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());

    axios
      .get(assestDetails + id)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_ASSET_DETAILS, payload: data.data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch({ type: GET_ASSET_DETAILS, payload: [] });
          dispatch(fetchError());
        }
      })
      .catch(error => {
        if (error?.response?.status === 401) {
          dispatch(setAuthUser(null));
          history.push("/signin");
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};

// Confirm Completion
export const funConfirmCompletion = (user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(confirmCompletion, user)
      .then(data => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess("Confirm Completion added successfully."));
          }, 2000);
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(
            fetchError("There was something issue in responding server.")
          );
        }
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message));
      });
  };
};

// Raise Dispute
export const funRaiseDispute = (user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(raiseDispute, user)
      .then(data => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess("Raise Dispute added successfully."));
          }, 2000);
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(
            fetchError("There was something issue in responding server.")
          );
        }
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message));
      });
  };
};

// Resolve Dispute
export const funResolveDispute = (user, id, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(resolveDispute + id, user)
      .then(data => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess("Resolve Dispute added successfully."));
          }, 2000);
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(
            fetchError("There was something issue in responding server.")
          );
        }
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message));
      });
  };
};
