import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setCurrentCallServiceRequest } from "redux/actions/CallCenterServiceRequest";

function FMAdminDemo() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setCurrentCallServiceRequest(null)); //added to clear create request after switching route
  }, []);
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "50vh"
      }}
    >
      <h1>Coming Soon...</h1>
    </div>
  );
}

export default FMAdminDemo;
