import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import AllRequests from "./AllRequests/index";
import Calendar from "./Calendar/index";

import PageContainer from "../../../@jumbo/components/PageComponents/layouts/PageContainer";
import { useSelector } from "react-redux";
const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    marginBottom: "10px"
  }
});
const breadcrumbs = [
  { label: "Home", link: "/" },
  { label: "All Requests", isActive: true }
];
export default function CenteredTabs() {
  const classes = useStyles();
  const { authUser } = useSelector(({ auth }) => auth);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <PageContainer heading="All Service Requests" breadcrumbs={breadcrumbs}>
      <Paper className={classes.root}>
        <Tabs value={value} indicatorColor="primary" textColor="primary">
          <Tab label="All Requests" onClick={e => setValue(0)} />
          <Tab label="Calendar" onClick={e => setValue(1)} />
          {/* {authUser?.fm_module_access[6]?.is_view?<Tab label="Parts List"  onClick={e=>setValue(0)}/>:null}
          {authUser?.fm_module_access[6]?.is_view?<Tab label="Calendar"  onClick={e=>setValue(1)}/>:null} */}
        </Tabs>
      </Paper>
      {value === 0 ? <AllRequests /> : null}
      {value === 1 ? <Calendar /> : null}
    </PageContainer>
  );
}
