import {
  ADD_CONTACTUSQUERY,
  DELETE_BULK_CONTACTUSQUERY,
  DELETE_CONTACTUSQUERY,
  EDIT_CONTACTUSQUERY,
  GET_CONTACTUSQUERY,
  SET_CONTACTUSQUERY_DETAILS,
  GET_CONTACTUSDETAILS
} from "../../@jumbo/constants/ActionTypes";

const INIT_STATE = {
  users: [],
  currentUser: null,
  details: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CONTACTUSQUERY: {
      return {
        ...state,
        users: action.payload
      };
    }
    case GET_CONTACTUSDETAILS: {
      return {
        ...state,
        details: action.payload
      };
    }
    case SET_CONTACTUSQUERY_DETAILS: {
      return {
        ...state,
        currentUser: action.payload
      };
    }
    case ADD_CONTACTUSQUERY: {
      return {
        ...state,
        users: [action.payload, ...state.users]
      };
    }
    case EDIT_CONTACTUSQUERY: {
      return {
        ...state,
        users: state.users.map(user =>
          user.id === action.payload.id ? action.payload : user
        )
      };
    }
    case DELETE_CONTACTUSQUERY: {
      return {
        ...state,
        users: state.users.filter(user => user.id !== action.payload)
      };
    }
    case DELETE_BULK_CONTACTUSQUERY: {
      return {
        ...state,
        users: state.users.filter(user => !action.payload.includes(user.id))
      };
    }
    default:
      return state;
  }
};
