import React from "react";
import { Box, makeStyles, Popover, Tooltip } from "@material-ui/core";
import { alpha } from "@material-ui/core/styles";
import { MenuList, Paper } from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { AuhMethods } from "../../../../../../services/auth";
import { CurrentAuthMethod } from "../../../../../constants/AppConstants";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  cardRoot: {
    "& .Cmt-header-root": {
      paddingTop: 4,
      paddingBottom: 4
    },
    "& .Cmt-card-content": {
      padding: "0 0 16px !important"
    }
  },
  typography: {
    padding: theme.spacing(2)
  },
  iconRoot: {
    position: "relative",
    color: alpha(theme.palette.common.white, 0.38),
    "&:hover, &.active": {
      color: theme.palette.common.white
    }
  },
  iconHeader: {
    color: theme.palette.text.primary,
    width: "auto",
    overflow: "hidden",
    fontSize: "1rem",
    cursor: "pointer",
    // minHeight: '48px',
    // paddingLeft:'16px',
    fontWeight: 400,
    lineHeight: 1.5,
    paddingTop: "6px",
    whiteSpace: "nowrap",
    paddingBottom: "6px",
    "&:hover, &.active": {
      color: theme.palette.primary.main
    }
  },
  iconHeaderActive: {
    color: theme.palette.primary.main
  },
  counterRoot: {
    color: theme.palette.common.white,
    border: `solid 1px ${theme.palette.common.white}`,
    backgroundColor: theme.palette.warning.main,
    width: 20
  },
  scrollbarRoot: {
    height: 300,
    padding: 16
  },
  popoverRoot: {
    "& .MuiPopover-paper": {
      width: 200
    }
  },
  profileicon: {
    cursor: "pointer"
  }
}));

const HeaderNotifications = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();
  const onOpenPopOver = event => {
    setAnchorEl(event.currentTarget);
  };
  const onClosePopOver = () => {
    setAnchorEl(null);
  };
  const onLogoutClick = () => {
    setAnchorEl(null);
    dispatch(AuhMethods[CurrentAuthMethod].onLogout());
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Box pr={2}>
      <Tooltip title="Menu" className={classes.profileicon}>
        {/* <IconButton
          onClick={onOpenPopOver}
          className={clsx(classes.iconRoot, 'Cmt-appIcon', {
            active: counter > 0,
          })}> */}

        <AccountCircleIcon onClick={onOpenPopOver} fontSize="large" />

        {/* </IconButton> */}
      </Tooltip>

      <Popover
        className={classes.popoverRoot}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={onClosePopOver}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        <Paper elevation={8}>
          <MenuList>
            <NavLink
              className={clsx(classes.iconHeader, "Cmt-nav-menu-link")}
              to={"/profile"}
            >
              {" "}
              <div
                className="ml-6 mt-2"
                onClick={e => {
                  setAnchorEl(null);
                }}
              >
                Profile
              </div>{" "}
            </NavLink>
            <NavLink
              className={clsx(classes.iconHeader, "Cmt-nav-menu-link")}
              to={"/about-us"}
            >
              {" "}
              <div
                className="ml-6 mt-2"
                onClick={e => {
                  setAnchorEl(null);
                }}
              >
                About Us
              </div>{" "}
            </NavLink>
            <NavLink
              className={clsx(classes.iconHeader, "Cmt-nav-menu-link")}
              to={"/terms-and-conditions"}
            >
              {" "}
              <div
                className="ml-6 mt-2"
                onClick={e => {
                  setAnchorEl(null);
                }}
              >
                Terms & Conditions
              </div>{" "}
            </NavLink>
            <NavLink
              className={clsx(classes.iconHeader, "Cmt-nav-menu-link")}
              to={"/privacy-policy"}
            >
              {" "}
              <div
                className="ml-6 mt-2"
                onClick={e => {
                  setAnchorEl(null);
                }}
              >
                Privacy Policy
              </div>{" "}
            </NavLink>
            <NavLink
              className={clsx(classes.iconHeader, "Cmt-nav-menu-link")}
              to={"/faq"}
            >
              {" "}
              <div
                className="ml-6 mt-2"
                onClick={e => {
                  setAnchorEl(null);
                }}
              >
                FAQ
              </div>{" "}
            </NavLink>
            <NavLink
              className={clsx(classes.iconHeader, "Cmt-nav-menu-link")}
              to={"/contact-us"}
            >
              {" "}
              <div
                className="ml-6 mt-2"
                onClick={e => {
                  setAnchorEl(null);
                }}
              >
                Contact Us
              </div>{" "}
            </NavLink>

            <span
              className={clsx(classes.iconHeader, "Cmt-nav-menu-link")}
              onClick={onLogoutClick}
            >
              {/* <ExitToAppIcon /> */}
              <div className="ml-6 mt-2 mb-2">Logout</div>
            </span>
          </MenuList>
        </Paper>
      </Popover>
    </Box>
  );
};

export default HeaderNotifications;
