import { fetchError, fetchStart, fetchSuccess } from "./Common";
import axios from "../../services/auth/jwt/config";
import { setAuthUser } from "./Auth";
import { history } from "redux/store";
import {
  GET_ASSETTYPE,
  GET_CALLCENTER_SERVICE_REQUEST_LIST,
  GET_CALL_CENTER_ASSET_TABLE_LIST,
  GET_CALL_CENTER_ISSUE_LIST,
  GET_CALL_CENTER_SERVICE_TYPE_LIST,
  GET_CALL_CENTER_SITE_LIST,
  GET_DOCUMENTS,
  GET_VIDEOS,
  SET_CURRENT_CALLCENTER_SERVICE_REQUEST
} from "../../@jumbo/constants/ActionTypes";
import {
  callCenterServiceRequestList,
  addCallCenterServiceRequest,
  callCenterSiteList,
  callCenterServiceRequestTable,
  callCenterServiceRequestDetail,
  assestTypeListApi,
  selfHelpList
} from "../../@jumbo/constants/ApiConstatnt";

export const getCallCenterServiceRequestList = (
  sortBy,
  sort,
  searchTerm,
  page,
  limit,
  filterOptions,
  callbackFun
) => {
  return dispatch => {
    dispatch(fetchStart());
    axios(callCenterServiceRequestList, {
      params: {
        sortBy: `${sortBy}:${sort}`,
        search: searchTerm,
        page: page + 1,
        limit: limit,
        status: filterOptions[0]
      }
    })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({
            type: GET_CALLCENTER_SERVICE_REQUEST_LIST,
            payload: data.data.data
          });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch({ type: GET_CALLCENTER_SERVICE_REQUEST_LIST, payload: [] });
          dispatch(fetchError());
        }
      })
      .catch(error => {
        if (error?.response?.status === 401) {
          dispatch(setAuthUser(null));
          history.push("/signin");
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};

export const getCallCenterServiceRequestDetail = (id, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios(callCenterServiceRequestDetail + id)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({
            type: SET_CURRENT_CALLCENTER_SERVICE_REQUEST,
            payload: data.data.data[0]
          });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(
            fetchError("There was something issue in responding server.")
          );
        }
      })
      .catch(error => {
        console.log("error", error?.response?.data);
        if (
          error?.response?.data?.message === "Certificate type data not found."
        ) {
          if (callbackFun) callbackFun();
          dispatch(fetchError());
          dispatch({
            type: SET_CURRENT_CALLCENTER_SERVICE_REQUEST,
            payload: []
          });
        } else if (error?.response?.status === 401) {
          dispatch(setAuthUser(null));
          history.push("/signin");
        } else {
          console.log("error", error?.response);
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};

export const getCallCenterAssetList = (
  assetId,
  siteId,
  tenantId,
  serviceType,
  callbackFun
) => {
  return dispatch => {
    dispatch(fetchStart());
    axios(
      callCenterServiceRequestTable +
        `?assetTypeId=${assetId}&siteId=${siteId}&tenantId=${tenantId}&serviceType=${serviceType}`
    )
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({
            type: GET_CALL_CENTER_ASSET_TABLE_LIST,
            payload: data.data.data
          });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError());
          dispatch({ type: GET_CALL_CENTER_ASSET_TABLE_LIST, payload: [] });
        }
      })
      .catch(error => {
        console.log("error", error?.response?.data);
        if (error?.response?.data?.message === "Asset data not found.") {
          if (callbackFun) callbackFun();
          dispatch(fetchError("Asset data not found."));
          dispatch({ type: GET_CALL_CENTER_ASSET_TABLE_LIST, payload: [] });
        } else if (error?.response?.status === 401) {
          dispatch(setAuthUser(null));
          history.push("/signin");
        } else {
          console.log("error", error?.response);
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};

export const getCallCenterSiteList = (data, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios(callCenterSiteList)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({
            type: GET_CALL_CENTER_SITE_LIST,
            payload: data.data.data
          });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError());
        }
      })
      .catch(error => {
        console.log("error", error?.response?.data);
        if (
          error?.response?.data?.message === "Certificate type data not found."
        ) {
          if (callbackFun) callbackFun();
          dispatch(fetchError());
          dispatch({ type: GET_CALL_CENTER_SITE_LIST, payload: [] });
        } else if (error?.response?.status === 401) {
          dispatch(setAuthUser(null));
          history.push("/signin");
        } else {
          console.log("error", error?.response);
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};

export const getSelfHelpList = (issueId, assetId, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios(selfHelpList + `issue_id=${issueId}&asset_id=${assetId}`)
      .then(data => {
        if (data.status === 200) {
          const documents = data?.data?.data?.data?.filter(
            item => item?.doc_type == 1
          );
          const videos = data?.data?.data?.data?.filter(
            item => item?.doc_type == 2
          );
          dispatch(fetchSuccess());
          dispatch({ type: GET_DOCUMENTS, payload: documents });
          dispatch({ type: GET_VIDEOS, payload: videos });
          if (documents?.length || videos?.length) callbackFun.isNextPressed();
          if (callbackFun && !documents?.length && !videos?.length)
            callbackFun.isSelfHelpViewed();
        } else {
          dispatch(fetchError());
        }
      })
      .catch(error => {
        console.log("error", error?.response?.data);
        if (
          error?.response?.data?.message === "Certificate type data not found."
        ) {
          if (callbackFun) callbackFun();
          dispatch(fetchError());
          dispatch({ type: GET_CALL_CENTER_SITE_LIST, payload: [] });
        } else if (error?.response?.status === 401) {
          dispatch(setAuthUser(null));
          history.push("/signin");
        } else {
          console.log("error", error?.response);
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};
export const getCallCenterServiceType = (id, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios(callCenterSiteList + "?siteId=" + id)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({
            type: GET_CALL_CENTER_SERVICE_TYPE_LIST,
            payload: data.data.data
          });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError());
        }
      })
      .catch(error => {
        console.log("error", error?.response?.data);
        if (
          error?.response?.data?.message === "Certificate type data not found."
        ) {
          if (callbackFun) callbackFun();
          dispatch(fetchError());
          dispatch({ type: GET_CALL_CENTER_SERVICE_TYPE_LIST, payload: [] });
        } else if (error?.response?.status === 401) {
          dispatch(setAuthUser(null));
          history.push("/signin");
        } else {
          console.log("error", error?.response);
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};

export const getCallCenterIssueList = (id, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios(callCenterSiteList + "?serviceTypeId=" + id)
      .then(data => {
        if (
          data.status === 200 &&
          data.data.message !== "Issue list not found."
        ) {
          dispatch(fetchSuccess());
          dispatch({
            type: GET_CALL_CENTER_ISSUE_LIST,
            payload: data.data.data
          });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch({ type: GET_CALL_CENTER_ISSUE_LIST, payload: [] });
          dispatch(fetchError());
        }
      })
      .catch(error => {
        console.log("error", error?.response?.data);
        if (
          error?.response?.data?.message === "Certificate type data not found."
        ) {
          if (callbackFun) callbackFun();
          dispatch(fetchError());
          dispatch({ type: GET_CALL_CENTER_ISSUE_LIST, payload: [] });
        } else if (error?.response?.status === 401) {
          dispatch(setAuthUser(null));
          history.push("/signin");
        } else {
          console.log("error", error?.response);
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};

// set current user for view tab
export const setCurrentCallServiceRequest = user => {
  return dispatch => {
    dispatch({ type: SET_CURRENT_CALLCENTER_SERVICE_REQUEST, payload: user });
  };
};

// add new FM provider
export const addNewServiceRequest = (user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(addCallCenterServiceRequest, user)
      .then(data => {
        console.log(data);
        if (data) {
          console.log("data1", data);
          dispatch(fetchSuccess("Service request created successfully."));
          setTimeout(() => {
            history.push(
              `/all-requests/all-requests-details/${data?.data?.data?._id}`
            );
          }, 2000);

          // dispatch({ type:  ADD_SELFHELPCONTENT, payload: data.data });
          if (callbackFun) callbackFun();
        } else {
          dispatch(
            fetchError("There was something issue in responding server.")
          );
        }
      })
      .catch(error => {
        if (error?.response?.status === 401) {
          dispatch(setAuthUser(null));
          history.push("/signin");
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};
export const getAssetType = () => {
  return dispatch => {
    dispatch(fetchStart());

    axios
      .get(assestTypeListApi)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_ASSETTYPE, payload: data.data.data });
        } else {
          dispatch(
            fetchError("There was something issue in responding server.")
          );
        }
      })
      .catch(error => {
        if (error?.response?.data?.message === "Asset data not found.") {
          dispatch(fetchError());
          dispatch({ type: GET_ASSETTYPE, payload: [] });
        } else if (error?.response?.status === 401) {
          dispatch(setAuthUser(null));
          history.push("/signin");
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};
