import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import Box from "@material-ui/core/Box";
import GridContainer from "../../../../@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import AppTextInput from "../../../../@jumbo/components/Common/formElements/AppTextInput";
import Button from "@material-ui/core/Button";
import { requiredMessage } from "../../../../@jumbo/constants/ErrorMessages";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {
  addNewItemsList,
  getOPenRequestList,
  updateItemList
} from "../../../../redux/actions/OpenRequest";
import {
  emptySpace,
  numberOnly,
  stringOnly
} from "../../../../@jumbo/constants/ValidationRegex";
import { Typography } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Checkbox from "@material-ui/core/Checkbox";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import InputAdornment from "@material-ui/core/InputAdornment";
import NoRecordFound from "../NoRecordFound";
import { capitalizeFLetter } from "@jumbo/utils/commonHelper";

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: "relative"
  },

  dialogTitleRoot: {
    "& .MuiTypography-h6": {
      fontSize: 16,
      color: theme.palette.text.primary
    }
  },
  serviceTypeList: {
    fontSize: 14,
    letterSpacing: 0.25,
    textOverflow: "ellipsis",
    wordWrap: "break-word"
  },
  typeList: {
    width: 535,
    fontSize: 14,
    height: 300,
    backgroundColor: theme.palette.background.default,
    overflow: "auto"
  },
  imageButton: {
    fontSize: "10px",
    marginLeft: "5px",
    color: theme.palette.text.primary,
    textOverflow: "ellipsis",
    maxWidth: "200px",
    display: "block",
    overflow: "hidden",
    whiteSpace: "nowrap"
  },
  imageRoot: {
    marginTop: "5%"
  },
  root: {
    "&:hover": {
      backgroundColor: "transparent"
    },
    "& .Mui-error": {
      color: "red"
    },
    "& .MuiFormHelperText-root": {
      color: "red"
    }
  },
  icon: {
    borderRadius: "50%",
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5"
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)"
    }
  },
  checkedIcon: {
    backgroundColor: "#137cbd",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""'
    },
    "input:hover ~ &": {
      backgroundColor: "#106ba3"
    }
  }
}));

const AddEditUser = ({ open, onCloseDialog, getSERVICETYPEParams }) => {
  const classes = useStyles();
  const { currentUser } = useSelector(
    ({ ITEMSLISTMANAGEMENT }) => ITEMSLISTMANAGEMENT
  );
  const { users } = useSelector(({ SERVICETYPE }) => SERVICETYPE);
  const {
    orderBy,
    order,
    debouncedSearchTerm,
    page,
    rowsPerPage,
    filterOptions
  } = getSERVICETYPEParams;
  const [itemName, setItemName] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [price, setPrice] = useState("");
  const [serviceTypeValue, setServiceTypeValue] = useState([]);
  const [firstNameError, setFirstNameError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [serviceTypeValueError, setServiceTypeValueError] = useState("");
  const [imageError, setImageError] = useState("");
  const [priceError, setPriceError] = useState("");
  const [status, setStatus] = useState(1);
  const [selectedServiceType, setSelectedServiceType] = useState([]);

  const onFilterOptionClick1 = (e, option) => {
    e.stopPropagation();
    const checkData = selectedServiceType?.map(item => {
      if (item._id === option._id) {
        return item.select
          ? { name: item.name, _id: item._id, select: false }
          : { name: item.name, _id: item._id, select: true };
      } else {
        return item;
      }
    });

    setSelectedServiceType(checkData);
    if (currentUser) {
      const filterArray = checkData?.filter(type => {
        if (type.select == true) {
          return type._id;
        }
      });

      setServiceTypeValue(filterArray);
    }
    setServiceTypeValueError("");
  };

  const onFilterOptionClick = (e, option) => {
    e.stopPropagation();
    setServiceTypeValue(prevState => {
      if (prevState.includes(option._id)) {
        return prevState.filter(item => item !== option._id);
      } else {
        return [...prevState, option._id];
      }
    });
    setServiceTypeValueError("");
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (currentUser) {
      setItemName(currentUser?.name);
      setDescription(currentUser?.description);
      setImage(currentUser?.image);
      setPrice(currentUser?.charge);
      setServiceTypeValue(currentUser?.service_type);
      const filterSelectedServiceTypeArray = users?.data?.map(item => {
        let data = false;
        data = currentUser?.service_type?.find(service =>
          item._id === service ? true : false
        );
        return data
          ? { name: item.name, _id: item._id, select: true }
          : { name: item.name, _id: item._id, select: false };
      });
      setSelectedServiceType(filterSelectedServiceTypeArray);
      setStatus(currentUser?.status);
    }
  }, [currentUser]);

  const onSubmitClick = () => {
    if (!itemName || itemName.match(emptySpace)) {
      setFirstNameError(requiredMessage);
    }
    if (!description || description.match(emptySpace)) {
      setDescriptionError(requiredMessage);
    }
    if (serviceTypeValue.length == 0) {
      setServiceTypeValueError(requiredMessage);
    }
    if (!image) {
      setImageError(requiredMessage);
    }
    if (!price.toString().match(numberOnly) || Number(price < 0)) {
      setPriceError("Enter positive number only.");
    }
    if (!price) {
      setPriceError(requiredMessage);
    }
    if (price && Number(price) < 1) {
      setPriceError("Price value should not be 0 .");
    }

    if (
      itemName &&
      !itemName.match(emptySpace) &&
      price &&
      price > 0 &&
      image &&
      description &&
      !description.match(emptySpace) &&
      serviceTypeValue.length &&
      image
    ) {
      onUserSave();
    }
  };

  const onUserSave = () => {
    if (currentUser) {
      if (!image.size) {
        setImage(null);
      }
      let newArray = serviceTypeValue?.map(item => {
        if (item.select == true) {
          return item._id;
        }
      });
      const nameCaps = capitalizeFLetter(itemName);
      const descriptionCaps = capitalizeFLetter(description);
      const formData = new FormData();
      formData.append("name", nameCaps);
      formData.append("itemImage", image?.size ? image : null);
      formData.append("description", descriptionCaps);
      formData.append("charge", price);
      formData.append(
        "service_type",
        newArray[0] == undefined ? serviceTypeValue : newArray
      );
      formData.append("status", status);
      formData.append("_id", currentUser?._id);

      dispatch(
        updateItemList(formData, () => {
          onCloseDialog();
          dispatch(
            getOPenRequestList(
              orderBy,
              order,
              debouncedSearchTerm,
              page,
              rowsPerPage,
              filterOptions
            )
          );
        })
      );
    } else {
      const nameCaps = capitalizeFLetter(itemName);
      const descriptionCaps = capitalizeFLetter(description);
      const formData = new FormData();
      formData.append("name", nameCaps);
      formData.append("itemImage", image);
      formData.append("description", descriptionCaps);
      formData.append("charge", price);
      formData.append("service_type", serviceTypeValue);
      formData.append("status", status);

      dispatch(
        addNewItemsList(formData, () => {
          onCloseDialog();
          dispatch(
            getOPenRequestList(
              orderBy,
              order,
              debouncedSearchTerm,
              page,
              rowsPerPage,
              filterOptions
            )
          );
        })
      );
    }
  };

  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <DialogTitle className={classes.dialogTitleRoot}>
        {currentUser ? "Edit Part Details" : "Add New Part"}
      </DialogTitle>
      <DialogContent dividers>
        <Box
          display="flex"
          flexDirection={{ xs: "column", md: "row" }}
          alignItems="center"
          mb={{ xs: 6, md: 5 }}
        >
          <GridContainer>
            <Grid item xs={12} sm={12}>
              <AppTextInput
                className={classes.root}
                fullWidth
                variant="outlined"
                label=" Part Name "
                value={itemName}
                onChange={e => {
                  setItemName(e.target.value);
                  setFirstNameError("");
                }}
                helperText={firstNameError}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <AppTextInput
                className={classes.root}
                fullWidth
                variant="outlined"
                label="Description"
                value={description}
                onChange={e => {
                  setDescription(e.target.value);
                  setDescriptionError("");
                }}
                helperText={descriptionError}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <Button variant="contained" component="label">
                <CloudUploadIcon />
                <p className={classes.imageButton}>
                  {image?.size ? image?.name : "Choose Image"}
                </p>
                <input
                  type="file"
                  hidden
                  onChange={event => {
                    setImage(event.target.files[0]);
                    setImageError("");
                  }}
                  accept="image/png, image/jpeg"
                />
              </Button>{" "}
              <br />
              {currentUser ? (
                <img src={image} width="200px" className={classes.imageRoot} />
              ) : (
                ""
              )}
              <p
                className="MuiFormHelperText-root MuiFormHelperText-contained MuiFormHelperText-marginDense"
                style={{ color: "red" }}
              >
                {imageError}
              </p>
            </Grid>

            {currentUser === null ? (
              <Grid item xs={12} sm={6}>
                <Typography className={classes.titleRoot}>
                  Service Type
                </Typography>
                <Box className={classes.typeList}>
                  {users.data.length > 0 ? (
                    users.data?.map((value, index) => {
                      const labelId = `transfer-list-all-item-${value}-label`;

                      return (
                        <ListItem
                          key={index}
                          role="listitem"
                          button
                          onClick={e =>
                            document.getElementById(value._id).click()
                          }
                        >
                          <ListItemIcon>
                            <Checkbox
                              onClick={e => onFilterOptionClick(e, value)}
                              id={value._id}
                              tabIndex={-1}
                              disableRipple
                            />
                          </ListItemIcon>
                          <ListItemText
                            id={labelId}
                            primary={`${value.name}`}
                            className={classes.serviceTypeList}
                          />
                        </ListItem>
                      );
                    })
                  ) : (
                    <NoRecordFound>
                      {"There are no records found."}
                    </NoRecordFound>
                  )}
                </Box>
                <p
                  className="MuiFormHelperText-root MuiFormHelperText-contained MuiFormHelperText-marginDense"
                  style={{ color: "red" }}
                >
                  {serviceTypeValueError}
                </p>
              </Grid>
            ) : (
              <Grid item xs={12} sm={6}>
                <Typography className={classes.titleRoot}>
                  {" "}
                  Service Type
                </Typography>
                <Box className={classes.typeList}>
                  {selectedServiceType?.map((value, index) => {
                    const labelId = `transfer-list-all-item-${value}-label`;

                    return (
                      <ListItem
                        key={index}
                        role="listitem"
                        button
                        onClick={e =>
                          document.getElementById(value._id).click()
                        }
                      >
                        <ListItemIcon>
                          <Checkbox
                            onClick={e => onFilterOptionClick1(e, value)}
                            checked={value.select}
                            tabIndex={-1}
                            disableRipple
                            id={value._id}
                          />
                        </ListItemIcon>
                        <ListItemText
                          id={labelId}
                          primary={`${value.name}`}
                          className={classes.serviceTypeList}
                        />
                      </ListItem>
                    );
                  })}
                </Box>
                <p
                  className="MuiFormHelperText-root MuiFormHelperText-contained MuiFormHelperText-marginDense"
                  style={{ color: "red" }}
                >
                  {serviceTypeValueError}
                </p>
              </Grid>
            )}

            <Grid item xs={12} sm={12}>
              <AppTextInput
                className={classes.root}
                fullWidth
                InputProps={{
                  inputProps: { min: 0 },
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  )
                }}
                variant="outlined"
                label="Part Price"
                value={price}
                onKeyPress={event => {
                  if (["-", "+", "e", "."].includes(event.key)) {
                    event.preventDefault();
                  }
                }}
                onChange={e => {
                  setPrice(e.target.value);
                  setPriceError("");
                }}
                helperText={priceError}
              />
            </Grid>
          </GridContainer>
        </Box>
        <Box display="flex" justifyContent="flex-end" mb={4}>
          <Button onClick={onCloseDialog} variant="outlined">
            Cancel
          </Button>

          <Box ml={2}>
            <Button
              variant="contained"
              color="primary"
              style={{ minWidth: "100px" }}
              onClick={onSubmitClick}
            >
              {currentUser ? "Update" : "Add"}
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddEditUser;

AddEditUser.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func
};
