const p_color = "#FC8119";
const t_color = "#3B1A45";
export const sidebarLightTheme = {
  bgColor: "#fff",
  textColor: t_color,
  textDarkColor: p_color,
  textActiveColor: "#F2F2F2",
  navHoverBgColor: "#E0E0E0",
  navActiveBgColor: p_color,
  borderColor: "rgba(33, 33, 33, 0.08)",
  ...JSON.parse(localStorage.getItem("sidebar-colors"))
};

export const sidebarSemiDarkTheme = {
  bgColor: "#363636",
  textColor: "rgba(255, 255, 255, 0.3)",
  textDarkColor: "#fff",
  textActiveColor: "#fff",
  navHoverBgColor: "rgba(187, 134, 252, 0.08)",
  navActiveBgColor: "#3f51b5",
  borderColor: "rgba(255, 255, 255, 0.08)",
  ...JSON.parse(localStorage.getItem("sidebar-colors"))
};

export const sidebarDarkTheme = {
  bgColor: "#363636",
  textColor: "rgba(255, 255, 255, 0.3)",
  textDarkColor: "#fff",
  textActiveColor: "#fff",
  navHoverBgColor: "rgba(187, 134, 252, 0.08)",
  navActiveBgColor: "#3f51b5",
  borderColor: "rgba(255, 255, 255, 0.08)",
  ...JSON.parse(localStorage.getItem("sidebar-colors"))
};

const themeSidebarColor = {
  light: sidebarLightTheme,
  "semi-dark": sidebarSemiDarkTheme,
  dark: sidebarDarkTheme
};

export default themeSidebarColor;
