import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import useStyles from "./index.style";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import moment from "moment";
import { history } from "redux/store";
import BuildIcon from "@material-ui/icons/Build";
import DescriptionIcon from "@material-ui/icons/Description";
import AlarmOnIcon from "@material-ui/icons/AlarmOn";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import ClearIcon from "@material-ui/icons/Clear";
import IconButton from "@material-ui/core/IconButton";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import { priorityColor } from "@jumbo/constants/stringConstant";
import EventIcon from "@material-ui/icons/Event";

export default function ResponsiveDialog({
  setOpen,
  open,
  events,
  serviceRequset
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const handleOpen = () => {
    setOpen(false);
    history.push(`/all-requests/all-requests-details/${serviceRequset?.id}`);
  };

  const onCloseDialog = () => {
    setOpen(false);
  };
  return (
    <Box
      onClick={event => {
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
      }}
    >
      <Dialog
        onClick={event => {
          event.stopPropagation();
          event.nativeEvent.stopImmediatePropagation();
        }}
        className={classes.dialogRoot}
        fullScreen={fullScreen}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <Box className={classes.userInfoRoot}>
          <Box mr={3} display="flex" alignItems="center">
            <Box mt={2}>
              <Box display="flex" alignItems="center" ml={2}>
                <Typography className={classes.titleRoot1}>
                  {serviceRequset?.priority === "P1" ? (
                    <Avatar
                      variant="square"
                      className={classes.square}
                      style={{ backgroundColor: "rgba(255, 26, 26, 0.7)" }}
                    >
                      {serviceRequset?.priority}
                    </Avatar>
                  ) : serviceRequset?.priority === "P2" ? (
                    <Avatar
                      variant="square"
                      className={classes.square}
                      style={{ backgroundColor: "rgba(255, 26, 26, 0.5)" }}
                    >
                      {serviceRequset?.priority}
                    </Avatar>
                  ) : serviceRequset?.priority === "P3" ? (
                    <Avatar
                      variant="square"
                      className={classes.square}
                      style={{ backgroundColor: "rgba(251, 126, 24, 0.7)" }}
                    >
                      {serviceRequset?.priority}
                    </Avatar>
                  ) : serviceRequset?.priority === "P4" ? (
                    <Avatar
                      variant="square"
                      className={classes.square}
                      style={{ backgroundColor: "rgba(251, 126, 24, 0.5)" }}
                    >
                      {serviceRequset?.priority}
                    </Avatar>
                  ) : serviceRequset?.priority === "P5" ? (
                    <Avatar
                      variant="square"
                      className={classes.square}
                      style={{ backgroundColor: "rgba(156, 114, 7, 0.5)" }}
                    >
                      {serviceRequset?.priority}
                    </Avatar>
                  ) : (
                    <Avatar
                      variant="square"
                      className={classes.square}
                      style={{ backgroundColor: "grey" }}
                    >
                      {serviceRequset?.priority
                        ? serviceRequset?.priority
                        : "NA"}
                    </Avatar>
                  )}
                </Typography>
                <Box ml={1}></Box>
              </Box>
            </Box>
          </Box>
          <Box ml={88} mt={-4} display="flex" alignItems="center">
            <Box ml={1}></Box>
            <Box mb={1}>
              <IconButton onClick={onCloseDialog}>
                <ClearIcon />
              </IconButton>
            </Box>
          </Box>
        </Box>

        <DialogContent>
          <DialogContentText>
            <Box px={6} py={5} mt={-2}>
              <Box
                display="flex"
                ml={-5}
                alignItems="center"
                mb={{ xs: 4, sm: 7 }}
                onClick={event => {
                  event.stopPropagation();
                  event.nativeEvent.stopImmediatePropagation();
                }}
              >
                <BuildIcon />
                <Box display="flex" ml={2}>
                  <Typography className={classes.titleRoot1}>
                    Service Type :
                  </Typography>

                  <Typography className={classes.titleRoot2}>
                    {serviceRequset?.title}
                  </Typography>
                </Box>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                mb={{ xs: 4, sm: 7 }}
                ml={-5}
              >
                <BusinessCenterIcon />
                <Box display="flex" ml={2}>
                  <Typography className={classes.titleRoot1}>
                    Reactive / Planned :{" "}
                  </Typography>
                  <Typography className={classes.titleRoot2}>
                    {serviceRequset?.request_create === 1
                      ? "Planned"
                      : "Reactive"}
                  </Typography>
                </Box>
              </Box>

              {serviceRequset?.request_create === 2 ? (
                <Box
                  display="flex"
                  alignItems="center"
                  mb={{ xs: 4, sm: 7 }}
                  ml={-5}
                >
                  <EventIcon />
                  <Box display="flex" ml={2}>
                    <Typography className={classes.titleRoot1}>
                      Requested On :
                    </Typography>

                    <Typography className={classes.DateField}>
                      {moment(serviceRequset?.start).format(
                        "hh:mm A DD/MM/YYYY"
                      )}
                    </Typography>
                  </Box>
                </Box>
              ) : null}

              <Box
                display="flex"
                alignItems="center"
                mb={{ xs: 4, sm: 7 }}
                ml={-5}
              >
                <EventIcon />
                <Box display="flex" ml={2}>
                  <Typography className={classes.titleRoot1}>
                    Due Date :{" "}
                  </Typography>
                  <Typography className={classes.titleRoot2}>
                    {!serviceRequset?.dueDate
                      ? "-"
                      : moment(serviceRequset?.dueDate).format("DD/MM/YYYY ")}
                  </Typography>
                </Box>
              </Box>

              <Box
                display="flex"
                alignItems="center"
                mb={{ xs: 4, sm: 7 }}
                ml={-5}
              >
                <EventAvailableIcon />
                <Box display="flex" ml={2}>
                  <Typography className={classes.titleRoot1}>
                    Completed On :
                  </Typography>
                  <Typography className={classes.titleRoot2}>{"-"}</Typography>
                </Box>
              </Box>

              {serviceRequset?.request_create === 2 ? (
                <Box
                  display="flex"
                  alignItems="center"
                  mb={{ xs: 4, sm: 7 }}
                  ml={-5}
                >
                  <Box display="flex" mr={2}>
                    <DescriptionIcon />
                    <Typography className={classes.titleRoot11}>
                      Description :
                    </Typography>
                    <Typography className={classes.DescriptionNote}>
                      {serviceRequset?.desc}
                    </Typography>
                  </Box>
                </Box>
              ) : null}
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Box alignItems="center" mb={4} mr={3}>
            <Button
              onClick={handleOpen}
              color="primary"
              autoFocus
              variant="contained"
            >
              Go to Details
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
