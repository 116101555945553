import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import Box from "@material-ui/core/Box";
import GridContainer from "@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { requiredMessage } from "@jumbo/constants/ErrorMessages";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { alpha } from "@material-ui/core/styles";
import RateReviewIcon from "@material-ui/icons/RateReview";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import TextField from "@material-ui/core/TextField";
import { funRaiseDispute, getTenantDetails } from "redux/actions/OpenRequest";
import ConfirmDialog from "@jumbo/components/Common/ConfirmDialog";
import { onlyNumberNotAllow } from "@jumbo/constants/ErrorMessages";
import { emptySpace, numberOnly2 } from "@jumbo/constants/ValidationRegex";
import { useParams } from "react-router";

const useStyles = makeStyles(theme => ({
  root: {
    "& .Mui-error": {
      color: "red"
    },
    "& .MuiFormHelperText-root": {
      color: "red"
    }
  },
  dialogRoot: {
    position: "relative",
    "& .MuiDialog-paperWidthSm": {
      width: "100%"
    }
  },
  dialogTitleRoot: {
    "& .MuiTypography-h6": {
      fontSize: 16,
      color: theme.palette.text.primary
    }
  },
  cardRoot: {
    position: "relative",
    "& .Cmt-card-content": {
      padding: 0,
      paddingBottom: 24
    }
  },
  scrollbarRoot: {
    height: 340
  },
  badgeRoot: {
    fontSize: 14,
    letterSpacing: 0.25,
    backgroundColor: alpha(theme.palette.warning.main, 0.15),
    color: theme.palette.warning.main,
    padding: "2px 10px",
    borderRadius: 30
  },
  tableRowCellRoot: {
    fontSize: 14,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 16,
    letterSpacing: 0.4,
    textAlign: "center",
    color: theme.palette.common.dark,
    borderBottom: "0 none",
    "&:first-child": {
      paddingLeft: 24
    },
    "&:last-child": {
      paddingRight: 24
    }
  },
  imageButton: {
    fontSize: "10px",
    marginLeft: "5px",
    color: theme.palette.text.primary,
    textOverflow: "ellipsis",
    maxWidth: "200px",
    display: "block",
    overflow: "hidden",
    whiteSpace: "nowrap"
  },
  textFieldRoot: {
    maxWidth: "500px",
    minWidth: "500px",
    fontWeight: "600",
    marginLeft: "15px",
    whiteSpace: "nowrap",
    "&.MuiTypography-body1": {
      fontSize: 14
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: alpha(theme.palette.common.dark, 0.12)
    },
    "& .Mui-error": {
      color: "red"
    },
    "& .MuiFormHelperText-root": {
      color: "red"
    }
  },
  titleRoot1: {
    fontWeight: "600",
    marginLeft: "15px",
    whiteSpace: "nowrap",
    "&.MuiTypography-body1": {
      fontSize: 16
    }
  },
  titleRoot2: {
    fontWeight: "600",
    marginLeft: "5px",
    whiteSpace: "nowrap",
    "&.MuiTypography-body1": {
      fontSize: 16
    }
  },
  imageButtonWrapper: {
    marginBottom: "10px",
    marginTop: "5px",
    marginLeft: "7px"
  },
  tableRowRoot: {
    position: "relative",
    transition: "all .2s",
    borderTop: `solid 1px ${theme.palette.borderColor.main}`,
    "&:hover": {
      backgroundColor: alpha(theme.palette.primary.main, 0.08),
      transform: "translateY(-4px)",
      boxShadow: `0 3px 10px 0 ${alpha(theme.palette.common.dark, 0.2)}`,
      borderTopColor: "transparent",
      "& $tableCellRoot": {
        color: theme.palette.text.primary,

        "&.success": {
          color: theme.palette.success.main
        }
      }
    }
  },
  tableCellRoot: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    fontSize: 14,
    letterSpacing: 0.25,
    textAlign: "center",
    color: theme.palette.text.primary,
    whiteSpace: "nowrap",
    borderBottom: "0 none",
    "&:first-child": {
      paddingLeft: 24,
      textAlign: "left"
    },

    "& .Cmt-avatar-more": {
      fontSize: 10,
      color: theme.palette.primary.main
    }
  },
  tableCellSecondaryColor: {
    color: theme.palette.text.secondary
  },
  blockRoot: {
    display: "block",
    fontSize: 14
  }
}));

const AddEditUser = ({ open, onCloseDialog }) => {
  const classes = useStyles();
  let { id } = useParams();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [reviewNote, setReviewNote] = useState("");
  const [reviewNoteError, setReviewNoteError] = useState("");
  const [imageError, setImageError] = useState("");
  const [assetImage, setAssetImage] = useState("");
  const { details } = useSelector(
    ({ ITEMSLISTMANAGEMENT }) => ITEMSLISTMANAGEMENT
  );
  const dispatch = useDispatch();

  const onSubmitClick = () => {
    const formData = new FormData();

    formData.append("_id", details?._id);
    formData.append("review", reviewNote);
    formData.append("service_request_dispute", assetImage);

    if (reviewNote.match(emptySpace)) {
      setReviewNoteError(requiredMessage);
    } else if (reviewNote.match(numberOnly2)) {
      setReviewNoteError(onlyNumberNotAllow);
    }
    // if (!assetImage) {
    //     ; setImageError(requiredMessage);
    // }
    if (!reviewNote.match(emptySpace) && !reviewNote.match(numberOnly2)) {
      dispatch(funRaiseDispute(formData, () => dispatch(getTenantDetails(id))));
      onCloseDialog();
    }
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };

  const handleConfirmDelete = () => {
    setOpenConfirmDialog(false);
  };

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
  };

  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <DialogTitle className={classes.dialogTitleRoot}>
        {"Raise Dispute"}
      </DialogTitle>
      <DialogContent dividers>
        <Box
          display="flex"
          flexDirection={{ xs: "column", md: "row" }}
          alignItems="center"
          mb={{ xs: 6, md: 5 }}
        >
          <GridContainer>
            <Grid item xs={12} sm={12}>
              <Box display="flex" mb={{ xs: 4, sm: 7 }}>
                <RateReviewIcon style={{ marginTop: "22px" }} />
                <TextField
                  className={classes.textFieldRoot}
                  fullWidth
                  id="standard-multiline-static"
                  display="flex"
                  label="Reviewer Notes :"
                  multiline
                  minRows={2}
                  onChange={e => {
                    setReviewNote(e.target.value);
                    setReviewNoteError("");
                  }}
                  helperText={reviewNoteError}
                />
              </Box>
            </Grid>

            <Grid display="flex" item xs={12} sm={12}>
              <Box display="flex">
                <Box mt={3} className={classes.titleRoot2}>
                  Upload Image :
                </Box>
                <Box>
                  <Button
                    variant="contained"
                    component="label"
                    className={classes.imageButtonWrapper}
                  >
                    <CloudUploadIcon />
                    <p className={classes.imageButton}>
                      {assetImage?.size ? assetImage?.name : "Choose Image"}
                    </p>
                    <input
                      type="file"
                      hidden
                      accept="image/png, image/gif, image/jpeg"
                      onChange={event => {
                        setAssetImage(event.target.files[0]);
                      }}
                    />
                  </Button>
                  {/* <br /> */}
                  {/* <p className="MuiFormHelperText-root MuiFormHelperText-contained MuiFormHelperText-marginDense" style={{ color: 'red' }}> {imageError} </p> */}
                </Box>
              </Box>
            </Grid>
          </GridContainer>
        </Box>

        <Box display="flex" justifyContent="flex-end" mb={4} mt={5}>
          <Button
            variant="contained"
            style={{ minWidth: "100px" }}
            color="primary"
            onClick={onSubmitClick}
          >
            Submit
          </Button>
          <Box ml={2}>
            <Button variant="outlined" onClick={onCloseDialog}>
              Cancel
            </Button>
          </Box>
        </Box>

        <ConfirmDialog
          open={openConfirmDialog}
          title={`Resolve Dispute`}
          content={"Are you sure, you want to resolve this dispute?"}
          onClose={handleCancelDelete}
          onConfirm={handleConfirmDelete}
        />
      </DialogContent>
    </Dialog>
  );
};

export default AddEditUser;

AddEditUser.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func
};
