import BuildIcon from "@material-ui/icons/Build";
import AssignmentIcon from "@material-ui/icons/Assignment";
import AssessmentIcon from "@material-ui/icons/Assessment";
import React from "react";

import UpdateIcon from "@material-ui/icons/Update";
const dashboardsMenus = [
  {
    name: "Create Service Request",
    icon: <BuildIcon />,
    type: "item",
    link: "/service-request"
  },
  {
    name: "Open Service Requests",
    icon: <UpdateIcon />,
    type: "item",
    link: "/open-requests"
  },
  {
    name: "All Service Requests",
    icon: <AssignmentIcon />,
    type: "item",
    link: "/all-requests"
  }
  // {
  //   name: 'Analytics',
  //   icon: <AssessmentIcon />,
  //   type: 'item',
  //   link: '/analytics',
  // },
];

export const sidebarNavs = [
  {
    name: "Menu",
    type: "section",
    children: dashboardsMenus
  }
];

export const horizontalDefaultNavs = [
  {
    name: "Menu",
    type: "collapse",
    children: dashboardsMenus
  }
];

export const minimalHorizontalMenus = [
  {
    name: "Menu",
    type: "collapse",
    children: dashboardsMenus
  }
];
