import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import Common from "./Common";
import TaskList from "./TaskList";
import MailApp from "./MailApp";
import ProfileApp from "./ProfileApp";
import WallApp from "./WallApp";
import Auth from "./Auth";
import Users from "./Users";
import ContentManager from "./ContentManager";
import ContactUsQuery from "./ContactUsQuery";
import Customization from "./Customization";
import CallCenterServiceRequest from "./CallCenterServiceRequest";
import ServiceRequest from "./ServiceRequest";
import OpenRequest from "./OpenRequest";

export default history =>
  combineReducers({
    router: connectRouter(history),
    common: Common,
    taskList: TaskList,
    mailApp: MailApp,
    auth: Auth,
    profileApp: ProfileApp,
    wallApp: WallApp,
    usersReducer: Users,
    CMS: ContentManager,
    CONTACTUSQUERY: ContactUsQuery,
    CUSTOMIZATION: Customization,
    CALLCENTER: CallCenterServiceRequest,
    SERVICEREQUEST: ServiceRequest,
    OPENREQUESTMANAGEMENT: OpenRequest
  });
