import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import PropTypes from "prop-types";
import React from "react";

const headCells = [
  { id: "#", numeric: false, disablePadding: false, label: "#" },
  {
    id: "service_types",
    numeric: false,
    disablePadding: false,
    label: "Service Types"
  },
  {
    id: "priority",
    numeric: false,
    disablePadding: false,
    label: "Priority"
  },
  { numeric: false, disablePadding: false, label: "Description" },
  {
    id: "request date-time",
    numeric: false,
    disablePadding: false,
    label: "Request Date-Time"
  },
  { id: "due date", numeric: false, disablePadding: false, label: "Due Date" },
  { numeric: false, disablePadding: false, label: "Reactive/Planned" },
  { numeric: false, disablePadding: false, label: "Status" }
];

function UserTableHead({ classes, order, orderBy, onRequestSort }) {
  const onSortOrderChange = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox"></TableCell>
        {headCells.map((headCell, index) => (
          <TableCell
            key={index}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            {headCell.label}
          </TableCell>
        ))}
        <TableCell align="center">Action</TableCell>
      </TableRow>
    </TableHead>
  );
}

UserTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

export default React.memo(UserTableHead);
