import React, { useState, useEffect } from "react";
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableRow
} from "@material-ui/core";
import "./index.css";
import PageContainer from "../../../../@jumbo/components/PageComponents/layouts/PageContainer";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { calendarData } from "../../../../@fake-db";
import useStyles from "./index.style";
import { useSelector, useDispatch } from "react-redux";

import { getOPenRequestList } from "../../../../redux/actions/OpenRequest";
import CalendarPopup from "./CalendarPopup";
import EventWrapper from "./EvnetWrapper";
import { priorityColor } from "@jumbo/constants/stringConstant";

const today = new Date();
const currentYear = today.getFullYear();

const localizer = momentLocalizer(moment);

const PopupCalendar = () => {
  const { users } = useSelector(
    ({ OPENREQUESTMANAGEMENT }) => OPENREQUESTMANAGEMENT
  );
  const { authUser } = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getOPenRequestList(
        "asc",
        "created_at",
        "",
        0,
        -1,
        -1,
        [],
        "",
        "",
        authUser?._id,
        [],
        []
      )
    );
  }, []);

  const [open, setOpen] = React.useState(false);
  const [serviceRequset, setServiceRequset] = React.useState(null);
  const event = users?.data?.map(item => {
    return {
      title: item?.service_type_details?.name,
      start: new Date(item.created_at * 1000),
      end: new Date(item.created_at * 1000),
      desc: item?.description,
      request_create: item?.request_type,
      priority: item?.priority,
      id: item?._id,
      dueDate: item.due_date ? new Date(item.due_date * 1000) : "",
      status: item?.service_status
    };
  });

  const classes = useStyles();
  const handelclickEvent = e => {
    e.stopPropagation();
    setServiceRequset(e);
    setOpen(true);
  };

  return (
    <PageContainer>
      <Paper className={classes.paper}>
        <Calendar
          onClick={event => event.stopPropagation()}
          localizer={localizer}
          views={["month"]}
          events={event || []}
          defaultDate={new Date()}
          popup
          onSelectEvent={handelclickEvent}
          components={{
            eventWrapper: EventWrapper
            // event: Event
          }}
        />
      </Paper>
    </PageContainer>
  );
};

export default PopupCalendar;
