import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import {
  Block,
  CheckCircleOutline,
  Edit,
  Visibility
} from "@material-ui/icons";
import CmtDropdownMenu from "../../../../@coremat/CmtDropdownMenu";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { sentMailToUser } from "../../../../redux/actions/Users";
import {
  getOPenRequestList,
  updateItemListStatus
} from "../../../../redux/actions/OpenRequest";
import { Activate, Deactivate } from "@jumbo/constants/stringConstant";
import moment from "moment";
import { history } from "redux/store";

const useStyles = makeStyles(theme => ({
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    fontWeight: "300",
    color: theme.palette.text.primary,
    textOverflow: "ellipsis",
    maxWidth: "150px",
    display: "block",
    overflow: "hidden",
    whiteSpace: "nowrap"
  },
  DateField: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.text.primary,
    fontWeight: 300,
    whiteSpace: "nowrap",
    maxWidth: "150px"
  },
  textSize: {
    fontWeight: 300
  }
}));

const UserListRow = ({
  row,
  isSelected,

  onUserEdit,
  onUserDelete,
  onUserView,
  getSERVICETYPEParams,
  indexId
}) => {
  const {
    orderBy,
    order,
    debouncedSearchTerm,
    page,
    rowsPerPage,
    filterOptions
  } = getSERVICETYPEParams;
  const { authUser } = useSelector(({ auth }) => auth);
  const classes = useStyles();
  const dispatch = useDispatch();
  const getUserActions = user => {
    const actions = [{ action: "view", label: "View", icon: <Visibility /> }];

    return actions;
  };
  const onUserMenuClick = menu => {
    if (menu.action === "view") {
      onUserView(row);
    } else if (menu.action === "edit") {
      onUserEdit(row);
    } else if (menu.action === "email") {
      dispatch(sentMailToUser());
    } else if (menu.action === "suspend") {
      dispatch(
        updateItemListStatus({ _id: row._id, status: 2 }, refreshContactList)
      );
    } else if (menu.action === "activate") {
      dispatch(
        updateItemListStatus({ _id: row._id, status: 1 }, refreshContactList)
      );
    } else if (menu.action === "delete") {
      onUserDelete(row);
    }
  };

  function refreshContactList() {
    dispatch(
      getOPenRequestList(
        orderBy,
        order,
        debouncedSearchTerm,
        page,
        rowsPerPage,
        filterOptions
      )
    );
  }
  const labelId = `enhanced-table-checkbox-${row?._id}`;
  const isItemSelected = isSelected(row?._id);
  const userActions = getUserActions(row);

  return (
    <TableRow
      hover
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row?._id}
      selected={isItemSelected}
    >
      <TableCell padding="checkbox"></TableCell>
      <TableCell>
        <Typography className={classes.titleRoot} component="div" variant="h4">
          {indexId + 1}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography className={classes.titleRoot} component="div" variant="h4">
          {!row?.service_type_details?.name
            ? "-"
            : row?.service_type_details?.name}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography className={classes.titleRoot} component="div" variant="h4">
          {!row?.priority ? "-" : row?.priority}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography className={classes.titleRoot} component="div" variant="h4">
          {!row?.description ? "-" : row?.description}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography className={classes.DateField} component="div" variant="h4">
          {!row?.created_at
            ? "-"
            : moment(row?.created_at * 1000).format("hh:mm A DD/MM/YYYY")}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography className={classes.DateField} component="div" variant="h4">
          {!row?.due_date
            ? "-"
            : moment(row?.due_date * 1000).format("DD/MM/YYYY")}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography className={classes.titleRoot} component="div" variant="h4">
          {!row?.request_type
            ? "-"
            : row?.request_type === 1
            ? "Planned"
            : "Reactive"}
        </Typography>
      </TableCell>
      <TableCell>
        {/* {row?.service_status === 1 || row?.service_status === 9 ? 'Request Mode' 
        : row?.service_status === 2 || row?.service_status === 3 ? 'Vendor Assigned' 
        : row?.service_status === 5 ? 'Dispute Raised'
        : "Service Completed"} */}
        <Typography className={classes.titleRoot} component="div" variant="h4">
          {!row?.service_status
            ? "-"
            : row?.service_status === 1
            ? `Request Mode`
            : "Vendor Assigned"}
        </Typography>
      </TableCell>
      {/* <TableCell align="center" onClick={(event) => event.stopPropagation()}>
        <CmtDropdownMenu
          items={userActions}
          onItemClick={onUserMenuClick}
          TriggerComponent={<Edit />}
        />
      </TableCell> */}

      <TableCell align="center" style={{ cursor: "pointer" }}>
        <Visibility
          onClick={() =>
            history.push(`/all-requests/all-requests-details/${row?._id}`)
          }
        />
      </TableCell>
    </TableRow>
  );
};

export default React.memo(UserListRow);
