import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { Visibility } from "@material-ui/icons";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

const useStyles = makeStyles(theme => ({
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.text.primary,
    textOverflow: "ellipsis",
    maxWidth: "150px",
    display: "block",
    overflow: "hidden",
    whiteSpace: "nowrap",
    fontWeight: 300
  },
  DateField: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.text.primary,
    // textOverflow: "ellipsis",
    maxWidth: "150px",
    // display: "block",
    // overflow: "hidden",
    whiteSpace: "nowrap",
    fontWeight: 300
  }
}));

const UserListRow = ({ row, onUserView, getFMProviderParams, indexId }) => {
  const {
    orderBy,
    order,
    debouncedSearchTerm,
    page,
    rowsPerPage,
    filterOptions
  } = getFMProviderParams;

  const classes = useStyles();
  const { authUser } = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();
  const getUserActions = user => {
    const actions = !authUser?.fm_module_access[9]?.is_edit
      ? [{ action: "view", label: "View", icon: <Visibility /> }]
      : [{ action: "view", label: "View", icon: <Visibility /> }];
  };
  const labelId = `enhanced-table-checkbox-${row._id}`;
  const userActions = getUserActions(row);

  return (
    <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
      <TableCell padding="checkbox"></TableCell>
      <TableCell>
        <Typography className={classes.titleRoot} component="div" variant="h4">
          {indexId + 1}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography className={classes.titleRoot} component="div" variant="h4">
          {row?.service_type_details?.name}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography className={classes.titleRoot} component="div" variant="h4">
          {row?.priority ? row?.priority : "-"}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography className={classes.titleRoot} component="div" variant="h4">
          {row?.description}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography className={classes.DateField} component="div" variant="h4">
          {moment(row?.created_at * 1000).format("hh:mm A DD/MM/YYYY")}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography className={classes.DateField} component="div" variant="h4">
          {row?.due_date
            ? moment(row?.due_date * 1000).format("DD/MM/YYYY")
            : "-"}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography className={classes.titleRoot} component="div" variant="h4">
          {row?.request_type === 1 ? "Planned" : "Reactive"}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography className={classes.titleRoot} component="div" variant="h4">
          {row?.service_status === 1 || row?.service_status === 9
            ? "Request Mode"
            : row?.service_status === 2 || row?.service_status === 3
            ? "Vendor Assigned"
            : row?.service_status === 5
            ? "Dispute Raised"
            : "Service Completed"}
        </Typography>
      </TableCell>
      <TableCell
        align="center"
        onClick={() => onUserView(row)}
        style={{ cursor: "pointer" }}
      >
        <Visibility />
      </TableCell>
    </TableRow>
  );
};

export default React.memo(UserListRow);
