import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Checkbox
} from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import { useDispatch, useSelector } from "react-redux";
import useStyles from "./index.style";
import {
  addNewServiceRequest,
  getAssetType,
  getCallCenterAssetList,
  getCallCenterIssueList,
  getCallCenterServiceType,
  getSelfHelpList,
  setCurrentCallServiceRequest
} from "redux/actions/CallCenterServiceRequest";
import GridContainer from "@jumbo/components/GridContainer";
import AppSelectBox from "@jumbo/components/Common/formElements/AppSelectBox";
import AppTextInput from "@jumbo/components/Common/formElements/AppTextInput";
import CmtCardHeader from "@coremat/CmtCard/CmtCardHeader";
import CmtCardContent from "@coremat/CmtCard/CmtCardContent";
import PerfectScrollbar from "react-perfect-scrollbar";
import { requiredMessage } from "@jumbo/constants/ErrorMessages";
import { emptySpace } from "@jumbo/constants/ValidationRegex";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { GET_CALL_CENTER_ASSET_TABLE_LIST } from "@jumbo/constants/ActionTypes";
import Select from "react-select";

function CreateServiceRequest({
  setIsNextPressed,
  setIsSelfHelpViewed,
  isSelfHelpViewed
}) {
  const authUser = useSelector(({ auth }) => auth);
  const {
    currentUser,
    serviceTypeList,
    issueList,
    assetTable,
    assetTypeList
  } = useSelector(({ CALLCENTER }) => CALLCENTER);
  const [serviceType, setServiceType] = useState("");
  const [serviceTypeError, setServiceTypeError] = useState("");
  const [assetType, setAssetType] = useState("");
  const [assetTypeError, setAssetTypeError] = useState("");
  const [issue, setIssue] = useState([]);
  const [issueError, setIssueError] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [asset, setAsset] = useState("");
  const [assetError, setAssetError] = useState("");
  const [assetImage, setAssetImage] = useState([]);
  const [imageError, setImageError] = useState("");
  const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);
  const dispatch = useDispatch();
  const classes = useStyles();
  console.log("serviceType : ", serviceType);
  console.log("assetType : ", assetType);
  console.log("issueList : ", issueList);
  // console.log("description : ",description)
  // console.log("issueType : ",issueType)
  // console.log("asset : ",asset)

  const handleSelectServiceType = e => {
    setServiceType(e?.value ?? "");
    if (e?.value !== undefined) {
      dispatch(getCallCenterIssueList(e?.value));
    }
  };

  useEffect(() => {
    setServiceType("");
    setDescription("");
    setAssetType("");
    setAsset("");
    setIssue("");
    dispatch({ type: GET_CALL_CENTER_ASSET_TABLE_LIST, payload: [] });
    dispatch(getCallCenterServiceType(authUser?.authUser?.location?.id));
    dispatch(getAssetType());
  }, []);

  useEffect(() => {
    if (currentUser) {
      setServiceType(currentUser?.service_type_id);
      setDescription(currentUser?.description);
      setAssetType(currentUser.assetType);
      setAsset(currentUser?.assetTable[0]?._id);
      setIssue(currentUser?.issue_id);
    }
  }, [currentUser]);

  useEffect(() => {
    if (assetType && serviceType) {
      dispatch(
        getCallCenterAssetList(
          assetType,
          authUser?.authUser?.location?.id,
          authUser?.authUser?._id,
          serviceType
        )
      );
      if (!currentUser) setAsset("");
    }
  }, [assetType, serviceType]);
  const callback = {
    isNextPressed: () => setIsNextPressed(true),
    isSelfHelpViewed: () => setIsSelfHelpViewed(true)
  };
  const onResetClick = () => {
    dispatch(setCurrentCallServiceRequest(null));
    setIsSelfHelpViewed(false);
    setIsNextPressed(false);
    setServiceType("");
    setDescription("");
    setAssetType("");
    setAsset("");
    setIssue("");
    dispatch({ type: GET_CALL_CENTER_ASSET_TABLE_LIST, payload: [] });
    setServiceTypeError("");
    setAssetError("");
    setAssetTypeError("");
    setDescriptionError("");
    setIssueError("");
    setImageError("");
  };
  const onNextClick = () => {
    if (!serviceType) setServiceTypeError(requiredMessage);
    if (!description || description.match(emptySpace))
      setDescriptionError(requiredMessage);
    if (!assetType) setAssetTypeError(requiredMessage);
    if (!issue) setIssueError(requiredMessage);
    if (!asset) setAssetError(requiredMessage);

    if (
      serviceType &&
      asset &&
      issue &&
      description &&
      !description.match(emptySpace) &&
      assetType &&
      !isSelfHelpViewed
    ) {
      dispatch(getSelfHelpList(issue, asset, callback));

      dispatch(
        setCurrentCallServiceRequest({
          service_type_id: serviceType,
          issue_id: issue,
          assetTable: assetTable?.filter(item => item?._id === asset),
          description: description,
          assetType: assetType
        })
      );
    }
    if (!assetImage?.length && isSelfHelpViewed) setImageError(requiredMessage);
    else if (assetImage?.length >= 6 && isSelfHelpViewed) {
      setImageError("Please select image between one to five.");
    }
    if (
      serviceType &&
      asset &&
      issue &&
      description &&
      !description.match(emptySpace) &&
      assetType &&
      isSelfHelpViewed &&
      assetImage?.length &&
      assetImage?.length < 6
    ) {
      onUserSave();
    }
  };

  const onUserSave = () => {
    const formData = new FormData();

    formData.append("service_type_id", serviceType);
    formData.append("issue_id", issue);
    formData.append("asset_id", asset);
    formData.append("description", description);
    if (assetImage && assetImage?.length < 6) {
      assetImage.forEach(element => {
        formData.append("service_request_image", element);
      });
    }

    if (assetImage) {
      dispatch(
        addNewServiceRequest(formData, () => {
          dispatch(setCurrentCallServiceRequest(null));
          setIsSelfHelpViewed(false);
          setIsNextPressed(false);
          setServiceType("");
          setDescription("");
          setAssetType("");
          setAsset("");
          setIssue("");
          setImageError("");
        })
      );
    }
  };

  return (
    <>
      <Box
        display="flex"
        p={10}
        flexDirection={{ xs: "column", md: "row" }}
        alignItems="center"
        mb={{ xs: 6, md: 5 }}
      >
        <GridContainer>
          <Grid item xs={12} sm={6}>
            <AppSelectBox
              className={classes.root}
              fullWidth
              data={serviceTypeList?.locationData[0]?.service_type_details}
              label="Service Type"
              valueKey="_id"
              variant="outlined"
              labelKey="name"
              value={serviceType}
              disabled={currentUser}
              onChange={e => {
                setIssue("");
                setServiceType(e.target.value);
                dispatch(getCallCenterIssueList(e.target.value));
                setServiceTypeError("");
              }}
              helperText={serviceTypeError}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AppSelectBox
              className={classes.root}
              fullWidth
              data={issueList}
              label="Problem Details"
              valueKey="_id"
              variant="outlined"
              labelKey="name"
              value={issue}
              disabled={currentUser}
              onChange={e => {
                setIssue(e.target.value);
                setIssueError("");
              }}
              helperText={issueError}
            />
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <AppTextInput
              className={classes.root}
              fullWidth
              variant="outlined"
              label="Description"
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
                setDescriptionError("");
              }}
              helperText={descriptionError}
            />
          </Grid> */}

          <Grid item xs={12} sm={6}>
            <AppSelectBox
              className={classes.root}
              fullWidth
              data={assetTypeList}
              label="Asset Type Problem Area"
              valueKey="_id"
              variant="outlined"
              labelKey="name"
              disabled={currentUser}
              value={assetType}
              onChange={e => {
                setAssetType(e.target.value);
                setAssetTypeError("");
              }}
              helperText={assetTypeError}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AppTextInput
              className={classes.root}
              fullWidth
              variant="outlined"
              label="Description"
              value={description}
              onChange={e => {
                setDescription(e.target.value);
                setDescriptionError("");
              }}
              helperText={descriptionError}
            />
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <AppSelectBox
              className={classes.root}
              fullWidth
              data={issueList}
              label="Problem Details"
              valueKey="_id"
              variant="outlined"
              labelKey="name"
              value={issue}
              disabled={currentUser}
              onChange={(e) => {
                setIssue(e.target.value);
                setIssueError("");
              }}
              helperText={issueError}
            />
          </Grid> */}
          {/* <Grid item xs={12} sm={6}>
              <AppSelectBox
              className={classes.root}
              fullWidth
              data={issueTypeList}
              label="Issue Type"
              valueKey="value"
              variant="outlined"
              labelKey="label"
              disabled={currentUser}
              value={issueType}
              onChange={(e) => {
                setIssueType(e.target.value);
                setIssueTypeError("");
              }}
              helperText={assetTypeError}
            />
          </Grid> */}
        </GridContainer>
      </Box>

      <CmtCardHeader title="Assets :"></CmtCardHeader>
      {!currentUser ? (
        <CmtCardContent>
          <PerfectScrollbar className={classes.scrollbarRoot}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell
                    className={classes.tableHeadRowCellRoot}
                  ></TableCell>
                  <TableCell className={classes.tableHeadRowCellRoot}>
                    Asset Name
                  </TableCell>
                  <TableCell className={classes.tableHeadRowCellRoot}>
                    Floor
                  </TableCell>
                  <TableCell className={classes.tableHeadRowCellRoot}>
                    Department
                  </TableCell>
                  <TableCell className={classes.tableHeadRowCellRoot}>
                    ID
                  </TableCell>
                  <TableCell className={classes.tableHeadRowCellRoot}>
                    Brand
                  </TableCell>
                  <TableCell className={classes.tableHeadRowCellRoot}>
                    Model
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!assetTable?.length ? (
                  <>
                    {" "}
                    <TableCell className={classes.tableRowCellRoot}></TableCell>
                    <TableCell className={classes.tableRowCellRoot}>
                      -
                    </TableCell>
                    <TableCell className={classes.tableRowCellRoot}>
                      -
                    </TableCell>
                    <TableCell className={classes.tableRowCellRoot}>
                      -
                    </TableCell>
                    <TableCell className={classes.tableRowCellRoot}>
                      -
                    </TableCell>
                    <TableCell className={classes.tableRowCellRoot}>
                      -
                    </TableCell>
                    <TableCell className={classes.tableRowCellRoot}>
                      -
                    </TableCell>
                  </>
                ) : null}
                {assetTable?.map(item => (
                  <TableRow>
                    <TableCell className={classes.tableCellRoot}>
                      <Box>
                        {" "}
                        <Checkbox
                          checked={item?._id === asset ? true : false}
                          onClick={e => {
                            setAsset(item._id);
                            setAssetError("");
                          }}
                        />
                      </Box>
                    </TableCell>

                    <TableCell className={classes.tableRowCellRoot}>
                      {item?.name}
                    </TableCell>
                    <TableCell className={classes.tableRowCellRoot}>
                      {item?.floor}
                    </TableCell>
                    <TableCell className={classes.tableRowCellRoot}>
                      {item?.department}
                    </TableCell>
                    <TableCell className={classes.tableRowCellRoot}>
                      {item?.asset_id}
                    </TableCell>
                    <TableCell className={classes.tableRowCellRoot}>
                      {item?.brand}
                    </TableCell>
                    <TableCell className={classes.tableRowCellRoot}>
                      {item?.model}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </PerfectScrollbar>
          <p
            className="MuiFormHelperText-root MuiFormHelperText-contained MuiFormHelperText-marginDense"
            style={{ color: "red" }}
          >
            {assetError}
          </p>
        </CmtCardContent>
      ) : (
        <CmtCardContent>
          <PerfectScrollbar className={classes.scrollbarRoot}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell
                    className={classes.tableHeadRowCellRoot}
                  ></TableCell>
                  <TableCell className={classes.tableHeadRowCellRoot}>
                    Asset Name
                  </TableCell>
                  <TableCell className={classes.tableHeadRowCellRoot}>
                    Floor
                  </TableCell>
                  <TableCell className={classes.tableHeadRowCellRoot}>
                    Department
                  </TableCell>
                  <TableCell className={classes.tableHeadRowCellRoot}>
                    ID
                  </TableCell>
                  <TableCell className={classes.tableHeadRowCellRoot}>
                    Brand
                  </TableCell>
                  <TableCell className={classes.tableHeadRowCellRoot}>
                    Model
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentUser?.assetTable?.map(item => (
                  <TableRow>
                    <TableCell className={classes.tableCellRoot}>
                      <Box>
                        {" "}
                        <Checkbox
                          checked={true}
                          onClick={e => {
                            setAsset(item._id);
                            setAssetError("");
                          }}
                        />
                      </Box>
                    </TableCell>

                    <TableCell className={classes.tableRowCellRoot}>
                      {item?.name}
                    </TableCell>
                    <TableCell className={classes.tableRowCellRoot}>
                      {item?.floor}
                    </TableCell>
                    <TableCell className={classes.tableRowCellRoot}>
                      {item?.department}
                    </TableCell>
                    <TableCell className={classes.tableRowCellRoot}>
                      {item?.asset_id}
                    </TableCell>
                    <TableCell className={classes.tableRowCellRoot}>
                      {item?.brand}
                    </TableCell>
                    <TableCell className={classes.tableRowCellRoot}>
                      {item?.model}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </PerfectScrollbar>
          <p
            class="MuiFormHelperText-root MuiFormHelperText-contained MuiFormHelperText-marginDense"
            style={{ color: "red" }}
          >
            {assetError}
          </p>
        </CmtCardContent>
      )}

      {!isSelfHelpViewed ? (
        <Box display="flex" justifyContent="center" mb={4} mt={5}>
          <Box mb={5} mr={5}>
            <Button
              variant="contained"
              style={{ minWidth: "100px" }}
              color="primary"
              onClick={onResetClick}
            >
              Reset
            </Button>
          </Box>
          <Box mb={5}>
            <Button
              variant="contained"
              style={{ minWidth: "100px" }}
              color="primary"
              onClick={onNextClick}
            >
              Next
            </Button>
          </Box>
        </Box>
      ) : null}
      {isSelfHelpViewed ? (
        <Box display="flex" justifyContent="left">
          <Box display="flex">
            <Box mt={3} className={classes.titleRoot2}>
              Upload Image :
            </Box>
            <Box ml={5} mb={5}>
              <Button
                variant="contained"
                component="label"
                className={classes.imageButtonWrapper}
              >
                <CloudUploadIcon />
                {/* <p className={classes.imageButton}>
                  {assetImage?.size ? assetImage?.name : "Choose Image"}
                </p> */}
                <input
                  type="file"
                  multiple
                  accept="image/png, image/gif, image/jpeg"
                  onChange={e => {
                    setAssetImage([...e.target.files]);
                    setImageError("");
                  }}
                />
              </Button>
              <br />
            </Box>
          </Box>
        </Box>
      ) : null}
      <p
        className="MuiFormHelperText-root MuiFormHelperText-contained MuiFormHelperText-marginDense"
        style={{ color: "red", marginLeft: "28px" }}
      >
        {" "}
        {imageError}{" "}
      </p>

      {isSelfHelpViewed ? (
        <Box display="flex" justifyContent="center">
          <Box display="flex">
            <Box mb={5}>
              <Button
                variant="contained"
                style={{ minWidth: "100px" }}
                color="primary"
                onClick={onResetClick}
              >
                Reset
              </Button>
            </Box>
            <Box ml={5} mb={5}>
              <Button
                variant="contained"
                style={{ minWidth: "100px" }}
                color="primary"
                onClick={onNextClick}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Box>
      ) : null}
    </>
  );
}

export default CreateServiceRequest;
