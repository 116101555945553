import React, { useEffect, useState } from "react";
import { Paper } from "@material-ui/core";
import PageContainer from "@jumbo/components/PageComponents/layouts/PageContainer";
import CreateServiceRequest from "./CreateServiceRequest";
import SelftHelpContent from "./SelftHelpContent";
import useStyles from "./index.style";
import ConfirmDialog from "@jumbo/components/Common/ConfirmDialog";
import { history } from "redux/store";
import { useDispatch } from "react-redux";
import { setCurrentCallServiceRequest } from "redux/actions/CallCenterServiceRequest";
const breadcrumbs = [
  { label: "Home", link: "/" },
  { label: "Service Requests", isActive: true }
];

const UsersModule = () => {
  const classes = useStyles();
  const [isNextPressed, setIsNextPressed] = useState(false);
  const [isSelfHelpViewed, setIsSelfHelpViewed] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const dispatch = useDispatch();
  const handleConfirm = () => {
    setOpenConfirmDialog(false);
    history.push("open-requests");
    dispatch(setCurrentCallServiceRequest(null));
  };
  return (
    <PageContainer
      heading={
        isNextPressed ? "Self Resolve Library" : "Create Service Request"
      }
      breadcrumbs={breadcrumbs}
    >
      <div className={classes.root}>
        <Paper className={classes.paper}>
          {!isNextPressed ? (
            <CreateServiceRequest
              setIsNextPressed={setIsNextPressed}
              isNextPressed={isNextPressed}
              isSelfHelpViewed={isSelfHelpViewed}
              setIsSelfHelpViewed={setIsSelfHelpViewed}
            />
          ) : (
            <SelftHelpContent
              setIsSelfHelpViewed={setIsSelfHelpViewed}
              setIsNextPressed={setIsNextPressed}
              setOpenConfirmDialog={setOpenConfirmDialog}
            />
          )}
        </Paper>
      </div>
      <ConfirmDialog
        open={openConfirmDialog}
        title={`Issue self resolution`}
        content={
          "Please confirm the issue is resolved. A service request will not be generated for this issue."
        }
        onClose={() => setOpenConfirmDialog(false)}
        onConfirm={handleConfirm}
      />
    </PageContainer>
  );
};

export default UsersModule;
