import {
  GET_ABOUTUS,
  ADD_ABOUTUS,
  GET_PRIVACYPOLICY,
  ADD_PRIVACYPOLICY,
  GET_TERMSANDCONDITIONS,
  ADD_TERMSANDCONDITIONS,
  GET_FAQ,
  ADD_FAQ
} from "../../@jumbo/constants/ActionTypes";

const INIT_STATE = {
  users: [],
  aboutUsContent: [],
  privacyPolicyContent: [],
  termsAndConditionsContent: [],
  FAQContent: [],
  currentUser: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ABOUTUS: {
      return {
        ...state,
        aboutUsContent: action.payload
      };
    }
    case ADD_ABOUTUS: {
      return {
        ...state,
        users: [action.payload, ...state.users]
      };
    }
    case GET_PRIVACYPOLICY: {
      return {
        ...state,
        privacyPolicyContent: action.payload
      };
    }
    case ADD_PRIVACYPOLICY: {
      return {
        ...state,
        users: [action.payload, ...state.users]
      };
    }
    case GET_TERMSANDCONDITIONS: {
      return {
        ...state,
        termsAndConditionsContent: action.payload
      };
    }
    case ADD_TERMSANDCONDITIONS: {
      return {
        ...state,
        users: [action.payload, ...state.users]
      };
    }
    case GET_FAQ: {
      return {
        ...state,
        FAQContent: action.payload
      };
    }
    case ADD_FAQ: {
      return {
        ...state,
        users: [action.payload, ...state.users]
      };
    }

    default:
      return state;
  }
};
