import React, { useState } from "react";
import Toolbar from "@material-ui/core/Toolbar";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import PropTypes from "prop-types";
import { Box, Button, Chip, Menu, MenuItem } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
// import { deleteBulkUsers } from '../../../../redux/actions/Users';
import ConfirmDialog from "@jumbo/components/Common/ConfirmDialog";
import CmtSearch from "@coremat/CmtSearch";
import useStyles from "./index.style";
import Checkbox from "@material-ui/core/Checkbox";
import Divider from "@material-ui/core/Divider";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const filterOptionsList = [
  { label: "Active", value: 1 },
  { label: "Inactive", value: 2 }
];

const statusList = [
  { label: "All", value: [1, 2, 3, 4, 5, 6, 7, 8, 9] },
  { label: "Request Mode", value: [1, 5, 9] },
  { label: "Vendor Assigned", value: [2, 3] },
  { label: "Service Completed", value: [4, 6, 7, 8] }
];

const requestList = [
  { label: "All", value: [1, 2] },
  { label: "Reactive", value: 2 },
  { label: "Planned", value: 1 }
];

const priorityList = [
  { label: "All", value: [] },
  { label: "P1", value: 1 },
  { label: "P2", value: 2 },
  { label: "P3", value: 3 },
  { label: "P4", value: 4 },
  { label: "P5", value: 5 }
];

const SortList = [
  { label: "By Priority - P1 to P5", value: "priority" },
  { label: "By Priority - P5 to P1", value: "priority" },
  { label: "By Request Date-Time - Newest to Oldest", value: "created_at" },
  { label: "By Request Date-Time - Oldest to Newest", value: "created_at" },
  { label: "By Due Date - Closest to Farthest", value: "due_date" },
  { label: "By Due Date - Farthest to Closest", value: "due_date" }
];

const UserTableToolbar = ({
  selected,
  setSelected,
  onUserAdd,
  serviceStatus,
  setServiceStatus,
  searchTerm,
  setSearchTerm,
  setSearchedData,
  setPage,
  requestStatus,
  setRequestStatus,
  priorityStatus,
  setPriorityStatus,
  setOrderBy,
  setOrder
}) => {
  const classes = useStyles();
  const { authUser } = useSelector(({ auth }) => auth);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [sortel, setSortel] = React.useState(null);
  const [value, setValue] = React.useState(
    "By Request Date-Time - Newest to Oldest"
  );
  const dispatch = useDispatch();

  const handleChange = event => {
    setValue(event.target.value);
  };

  const handleSortPriorityAsc = event => {
    setOrderBy("priority");
    setOrder("desc");
  };
  const handleSortPriorityDesc = event => {
    setOrderBy("priority");
    setOrder("asc");
  };

  const handelSortDateTimeAsc = () => {
    setOrderBy("created_at");
    setOrder("desc");
  };

  const handelSortDateTimeDesc = () => {
    setOrderBy("created_at");
    setOrder("asc");
  };

  const handelSortDueDateAsc = () => {
    setOrderBy("due_date");
    setOrder("desc");
  };
  const handelSortDueDateDesc = () => {
    setOrderBy("due_date");
    setOrder("asc");
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleSortClick = event => {
    setSortel(event.currentTarget);
  };

  const handleSortClose = () => {
    setSortel(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onDeleteCLick = () => {
    setOpenConfirmDialog(true);
  };

  const handleConfirmDelete = () => {
    setOpenConfirmDialog(false);
    // dispatch(deleteBulkUsers(selected, () => setSelected([])));
  };

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
  };

  const onFilterOptionClick = option => {
    setServiceStatus(prevState => {
      if (prevState.includes(option.value)) {
        return prevState.filter(item => item !== option.value);
      } else {
        setPage(0);
        return [option.value];
      }
    });
  };

  const onRequestStatusClick = option => {
    setRequestStatus(prevState => {
      if (prevState.includes(option.value)) {
        return prevState.filter(item => item !== option.value);
      } else {
        setPage(0);
        return [option.value];
      }
    });
  };

  const onPriorityStatusClick = option => {
    setPriorityStatus(prevState => {
      if (prevState.includes(option.value)) {
        return prevState.filter(item => item !== option.value);
      } else {
        setPage(0);
        return [option.value];
      }
    });
  };

  const onChipDelete = option => {
    setServiceStatus(serviceStatus.filter(item => item !== option.value));
  };

  const onRequestStatusChipDelete = option => {
    setRequestStatus(requestStatus.filter(item => item !== option.value));
  };

  const onPriorityStatusChipDelete = option => {
    setPriorityStatus(priorityStatus.filter(item => item !== option.value));
  };

  const onSearchChipDelete = () => {
    setSearchTerm("");
    setSearchedData("debouncedSearchPagevalidation");
  };

  const numSelected = selected.length;

  const handelClearAll = () => {
    setPriorityStatus([]);
    setRequestStatus([]);
    setServiceStatus([]);
  };

  return (
    <React.Fragment>
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0
        })}
      >
        {numSelected > 0 ? (
          <Typography
            className={classes.title}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            className={classes.title}
            variant="h4"
            id="tableTitle"
            component="div"
          >
            {authUser?.fm_module_access[9]?.is_add ? (
              <Button
                color="primary"
                variant="contained"
                onClick={() => onUserAdd(true)}
              >
                Add New Vendor
              </Button>
            ) : null}
          </Typography>
        )}

        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton aria-label="delete" onClick={onDeleteCLick}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <React.Fragment>
            <CmtSearch
              onChange={e => setSearchTerm(e.target.value)}
              value={searchTerm}
              border={false}
              onlyIcon
            />
            <div className={classes.chipsRoot}>
              {searchTerm && (
                <Chip
                  color="secondary"
                  label={searchTerm?.substring(0, 8) + "..."}
                  onDelete={onSearchChipDelete}
                />
              )}
              {statusList.map(
                (option, index) =>
                  serviceStatus.includes(option.value) && (
                    <Chip
                      color="secondary"
                      key={index}
                      label={option.label}
                      onDelete={() => onChipDelete(option)}
                    />
                  )
              )}
              {requestList.map(
                (option, index) =>
                  serviceStatus.includes(option.value) && (
                    <Chip
                      color="secondary"
                      key={index}
                      label={option.label}
                      onDelete={() => onRequestStatusChipDelete(option)}
                    />
                  )
              )}
              {priorityList.map(
                (option, index) =>
                  priorityStatus.includes(option.value) && (
                    <Chip
                      color="secondary"
                      key={index}
                      label={option.label}
                      onDelete={() => onPriorityStatusChipDelete(option)}
                    />
                  )
              )}
            </div>
            <Tooltip title="Sort list">
              <IconButton aria-label="sort list" onClick={handleSortClick}>
                <ImportExportIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Filter list">
              <IconButton aria-label="filter list" onClick={handleClick}>
                <FilterListIcon />
              </IconButton>
            </Tooltip>
            <Menu
              transformOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <Box display="flex">
                <Box>
                  <span className={classes.menuItem}>{"By Priority"}</span>
                  {priorityList.map((option, index) => (
                    <MenuItem
                      key={index}
                      onClick={() => onPriorityStatusClick(option)}
                    >
                      <Checkbox
                        checked={priorityStatus.includes(option.value)}
                        inputProps={{ "aria-labelledby": option.label }}
                      />
                      {option.label}
                    </MenuItem>
                  ))}
                </Box>

                <Divider orientation="vertical" flexItem />

                <Box>
                  <span className={classes.menuItem}>{"By Status"}</span>
                  {statusList.map((option, index) => (
                    <MenuItem
                      key={index}
                      onClick={() => onFilterOptionClick(option)}
                    >
                      <Checkbox
                        checked={serviceStatus.includes(option.value)}
                        inputProps={{ "aria-labelledby": option.label }}
                      />
                      {option.label}
                    </MenuItem>
                  ))}
                </Box>

                <Divider orientation="vertical" flexItem />

                <Box>
                  <span className={classes.menuItem}>{"By Request"}</span>
                  {requestList.map((option, index) => (
                    <MenuItem
                      key={index}
                      onClick={() => onRequestStatusClick(option)}
                    >
                      <Checkbox
                        checked={requestStatus.includes(option.value)}
                        inputProps={{ "aria-labelledby": option.label }}
                      />
                      {option.label}
                    </MenuItem>
                  ))}
                </Box>
              </Box>
              <Button
                onClick={handelClearAll}
                variant="outlined"
                style={{ marginLeft: "342px" }}
              >
                Clear All
              </Button>
            </Menu>

            <Menu
              // style={{float: 'right'}}
              transformOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              anchorEl={sortel}
              open={Boolean(sortel)}
              onClose={handleSortClose}
            >
              {/* <Box> */}
              <div style={{ paddingLeft: "10px" }}>
                {/* <MenuItem> */}
                <RadioGroup
                  aria-label="gender"
                  name="gender1"
                  value={value}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="By Priority - P1 to P5"
                    control={<Radio />}
                    label="By Priority - P1 to P5"
                    onChange={handleSortPriorityAsc}
                  />
                  <FormControlLabel
                    value="By Priority - P5 to P1"
                    control={<Radio />}
                    label="By Priority - P5 to P1"
                    onChange={handleSortPriorityDesc}
                  />
                  <FormControlLabel
                    value="By Request Date-Time - Newest to Oldest"
                    control={<Radio />}
                    label="By Request Date-Time - Newest to Oldest"
                    onChange={handelSortDateTimeAsc}
                  />
                  <FormControlLabel
                    value="By Request Date-Time - Oldest to Newest"
                    control={<Radio />}
                    label="By Request Date-Time - Oldest to Newest"
                    onChange={handelSortDateTimeDesc}
                  />
                  <FormControlLabel
                    value="By Due Date - Closest to Farthest"
                    control={<Radio />}
                    label="By Due Date - Closest to Farthest"
                    onChange={handelSortDueDateAsc}
                  />
                  <FormControlLabel
                    value="By Due Date - Farthest to Closest"
                    control={<Radio />}
                    label="By Due Date - Farthest to Closest"
                    onChange={handelSortDueDateDesc}
                  />
                </RadioGroup>
              </div>
              {/* </MenuItem> */}
              {/* </Box> */}
            </Menu>
          </React.Fragment>
        )}
      </Toolbar>

      <ConfirmDialog
        open={openConfirmDialog}
        title={`Confirm delete users`}
        content={"Are you sure, you want to delete selected users?"}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
      />
    </React.Fragment>
  );
};

UserTableToolbar.propTypes = {
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  filterOptions: PropTypes.array,
  setFilterOptions: PropTypes.func,
  priorityType: PropTypes.array,
  setPriorityType: PropTypes.func,
  serviceStatus: PropTypes.array,
  setServiceStatus: PropTypes.func,
  requestType: PropTypes.array,
  onFilterRequestClick: PropTypes.func,
  searchTerm: PropTypes.string,
  setSearchTerm: PropTypes.func,
  onUserAdd: PropTypes.func
};

export default React.memo(UserTableToolbar);
