import React, { useEffect, useState } from "react";
import { Box, Button, Dialog, Grid, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import useStyles from "./index.style";
import {
  getAssetType,
  setCurrentCallServiceRequest
} from "redux/actions/CallCenterServiceRequest";
import GridContainer from "@jumbo/components/GridContainer";
import CmtCardContent from "@coremat/CmtCard/CmtCardContent";
import PerfectScrollbar from "react-perfect-scrollbar";
import { history } from "redux/store";
import CmtList from "@coremat/CmtList";
import ListEmptyResult from "@coremat/CmtList/ListEmptyResult";
import { JumboCard } from "@jumbo/components/Common";
import CmtMediaObject from "@coremat/CmtMediaObject";

function SelftHelpContent({
  setIsNextPressed,
  setIsSelfHelpViewed,
  setOpenConfirmDialog
}) {
  const { Documents, Videos } = useSelector(({ CALLCENTER }) => CALLCENTER);
  const [open, setOpen] = useState(false);
  const [link, setLink] = useState(null);
  const dispatch = useDispatch();
  const classes = useStyles();
  useEffect(() => {
    // dispatch(getCallCenterSiteList());
    dispatch(getAssetType());
  }, []);
  const onCloseDialog = () => {
    setOpen(false);
    setLink(null);
  };
  const renderRowDoc = (item, index) => {
    return (
      <Box mb={2} key={index}>
        <JumboCard>
          <CmtMediaObject
            onClick={() => window.open(item?.link)}
            style={{
              alignItems: "center",
              cursor: "pointer",
              maxHeight: "40px"
            }}
            avatar={
              item.profile_pic ??
              process.env.PUBLIC_URL +
                "/images/document-icon-file-text-document-a-sheet-of-paper-document-symbol-for-modern-websites-and-mobile-app-ui-designs-vector (1).webp"
            }
            titleProps={{ className: classes.titleRoot }}
          >
            <Typography className={classes.descText} variant="body2">
              {item.title}
            </Typography>
          </CmtMediaObject>
        </JumboCard>
      </Box>
    );
  };
  const renderRowVideo = (item, index) => {
    return (
      <Box mb={2} key={index}>
        <JumboCard>
          <CmtMediaObject
            onClick={() => {
              setLink(item.link);
              setOpen(true);
            }}
            style={{
              alignItems: "center",
              cursor: "pointer",
              maxHeight: "40px"
            }}
            avatar={
              item.profile_pic ??
              process.env.PUBLIC_URL +
                "/images/aaa74e72580654221b298dc24c058a9b.jpeg"
            }
            avatarPos="top"
            titleProps={{ className: classes.titleRoot }}
          >
            <Typography
              className={classes.descText}
              variant="body2"
              onClick={() => {
                setLink(item.link);
                setOpen(true);
              }}
            >
              {item.title}
            </Typography>
          </CmtMediaObject>
        </JumboCard>
      </Box>
    );
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onCloseDialog}
        className={classes.dialogRoot}
      >
        <video width="600" controls>
          <source src={link} />
        </video>
        <Box ml={4} p={1}>
          <a
            href={link}
            target="_blank"
            style={{ fontStyle: "Bold", textDecoration: "underline" }} rel="noreferrer"
          >
            Click here
          </a>{" "}
          if video is not playing.{" "}
        </Box>
      </Dialog>
      <Box
        p={10}
        flexDirection={{ xs: "column", md: "row" }}
        alignItems="center"
        mb={{ xs: 6, md: 5 }}
      >
        <Typography>
          {" "}
          We have prepared some content that may help with this issue. You can
          try to use these or continue with the request.
        </Typography>
        <br />
        <CmtCardContent>
          <GridContainer>
            <Grid className={classes.tableHeadRowCellRoot} item xs={12} sm={6}>
              Videos
            </Grid>
            <Grid className={classes.tableHeadRowCellRoot} item xs={12} sm={6}>
              Documents
            </Grid>
            <Grid item xs={12} sm={6} className={classes.itemRoot}>
              <CmtList
                data={Videos}
                renderRow={renderRowVideo}
                onEndReached={() => console.log("You have reached end of list")}
                ListEmptyComponent={
                  <ListEmptyResult loader={false} content="No video found." />
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.itemRoot}>
              <CmtList
                data={Documents}
                renderRow={renderRowDoc}
                onEndReached={() => console.log("You have reached end of list")}
                ListEmptyComponent={
                  <ListEmptyResult
                    loader={false}
                    content="No document found."
                  />
                }
              />
            </Grid>
          </GridContainer>
          {/* <PerfectScrollbar
            className={classes.scrollbarRoot}
          ></PerfectScrollbar> */}
        </CmtCardContent>
      </Box>
      <Box display="flex" justifyContent="center" mb={4} mt={5}>
        <Box mb={5} mr={5}>
          <Button
            variant="contained"
            style={{ minWidth: "188.25px" }}
            color="primary"
            onClick={() => {
              setOpenConfirmDialog(true);
            }}
          >
            Resolved
          </Button>
        </Box>
        <Box mb={5}>
          <Button
            variant="contained"
            style={{ minWidth: "100px" }}
            color="primary"
            onClick={() => {
              setIsNextPressed(false);
              setIsSelfHelpViewed(true);
            }}
          >
            It wasn't helpful
          </Button>
        </Box>
      </Box>
    </>
  );
}

export default SelftHelpContent;
