import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableRow
} from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import UserListRow from "./UserListRow";
import UserTableHead from "./UserTableHead";
import UserTableToolbar from "./UserTableToolbar";
import { useDispatch, useSelector } from "react-redux";
import ConfirmDialog from "@jumbo/components/Common/ConfirmDialog";
import { useDebounce } from "@jumbo/utils/commonHelper";
import useStyles from "./index.style";
import UserDetailView from "./UserDetailView";
import NoRecordFound from "./NoRecordFound";
import PageContainer from "@jumbo/components/PageComponents/layouts/PageContainer";
import { history } from "redux/store";
import { getOPenRequestList } from "redux/actions/OpenRequest";
import { setCurrentCallServiceRequest } from "redux/actions/CallCenterServiceRequest";

// const breadcrumbs = [
//   { label: 'Home', link: '/' },
//   { label: 'All Requests', isActive: true },
// ];

const UsersModule = () => {
  const classes = useStyles();
  const { users } = useSelector(
    ({ OPENREQUESTMANAGEMENT }) => OPENREQUESTMANAGEMENT
  );
  const authUser = useSelector(({ auth }) => auth);
  const [orderBy, setOrderBy] = React.useState("created_at");
  const [order, setOrder] = React.useState("desc");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selected, setSelected] = React.useState([]);
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [openUserDialog, setOpenUserDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState({ name: "" });
  const [usersFetched, setUsersFetched] = useState(false);
  const [isFilterApplied, setFilterApplied] = useState(false);
  const [filterOptions, setFilterOptions] = React.useState([]);
  const [serviceStatus, setServiceStatus] = React.useState([]);
  const [requestStatus, setRequestStatus] = React.useState([]);
  const [priorityStatus, setPriorityStatus] = React.useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchedData, setSearchedData] = useState(
    "debouncedSearchPagevalidation"
  );
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCurrentCallServiceRequest(null)); //added to clear create request after switching route
    if (debouncedSearchTerm && debouncedSearchTerm !== searchedData) {
      dispatch(
        getOPenRequestList(
          orderBy,
          order,
          debouncedSearchTerm,
          0,
          rowsPerPage,
          filterOptions,
          serviceStatus,
          startDate,
          endDate,
          authUser?._id,
          requestStatus,
          priorityStatus,
          () => {
            setFilterApplied(!!debouncedSearchTerm);
            setUsersFetched(true);
          }
        )
      );
      setSearchedData(debouncedSearchTerm);
      setPage(0);
    } else {
      dispatch(
        getOPenRequestList(
          orderBy,
          order,
          debouncedSearchTerm,
          page,
          rowsPerPage,
          filterOptions,
          serviceStatus,
          startDate,
          endDate,
          authUser?._id,
          requestStatus,
          priorityStatus,
          () => {
            setFilterApplied(!!debouncedSearchTerm);
            setUsersFetched(true);
          }
        )
      );
    }
  }, [
    dispatch,
    filterOptions,
    debouncedSearchTerm,
    orderBy,
    order,
    page,
    rowsPerPage,
    serviceStatus,
    startDate,
    endDate,
    authUser?._id,
    requestStatus,
    priorityStatus
  ]);

  const handleCloseUserDialog = () => {
    setOpenUserDialog(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrderBy(property);
    setOrder(isAsc ? "desc" : "asc");
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelected = users.map(n => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleRowClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleUserView = user => {
    history.push(`/all-requests/all-requests-details/${user?._id}`);
  };

  const handleCloseViewDialog = () => {
    setOpenViewDialog(false);
  };
  const handleUserUpdate = user => {
    setOpenUpdateDialog(true);
  };
  const handleCloseUpdateDialog = () => {
    setOpenUpdateDialog(false);
  };

  const handleUserEdit = user => {
    setOpenUserDialog(true);
  };

  const handleUserDelete = user => {
    setSelectedUser(user);
    setOpenConfirmDialog(true);
  };

  const handleConfirmDelete = () => {
    setOpenConfirmDialog(false);
  };

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
  };

  const isSelected = id => selected.indexOf(id) !== -1;

  return (
    // <PageContainer heading="All Service Requests" breadcrumbs={breadcrumbs}>
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <UserTableToolbar
          selected={selected}
          setSelected={setSelected}
          onUserAdd={setOpenUserDialog}
          filterOptions={filterOptions}
          setServiceStatus={setServiceStatus}
          serviceStatus={serviceStatus}
          requestStatus={requestStatus}
          setRequestStatus={setRequestStatus}
          priorityStatus={priorityStatus}
          setPriorityStatus={setPriorityStatus}
          setFilterOptions={setFilterOptions}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          setSearchedData={setSearchedData}
          setPage={setPage}
          setOrderBy={setOrderBy}
          setOrder={setOrder}
        />
        <TableContainer className={classes.container}>
          <Table
            stickyHeader
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="sticky enhanced table"
          >
            <UserTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={users?.total ?? 1}
            />
            <TableBody>
              {!!users?.data?.length ? (
                users?.data
                  ?.slice(0 * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <UserListRow
                      indexId={page * rowsPerPage + index}
                      key={index}
                      row={row}
                      onRowClick={handleRowClick}
                      onUserEdit={handleUserEdit}
                      onUserDelete={handleUserDelete}
                      onUserView={handleUserView}
                      onUserUpdate={handleUserEdit}
                      isSelected={isSelected}
                      getFMProviderParams={{
                        orderBy,
                        order,
                        debouncedSearchTerm,
                        page,
                        rowsPerPage,
                        filterOptions
                      }}
                    />
                  ))
              ) : (
                <TableRow style={{ height: 53 * 6 }}>
                  <TableCell colSpan={9} rowSpan={10}>
                    {isFilterApplied ? (
                      <NoRecordFound>There are no records found.</NoRecordFound>
                    ) : (
                      <NoRecordFound>
                        {"There are no records found."}
                      </NoRecordFound>
                    )}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={users?.total ?? 1}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </Paper>

      {openViewDialog && (
        <UserDetailView
          open={openViewDialog}
          onCloseDialog={handleCloseViewDialog}
        />
      )}
      <ConfirmDialog
        open={openConfirmDialog}
        title={`Confirm delete`}
        content={"Are you sure, you want to delete this vendor?"}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
      />
    </div>
    // </PageContainer>
  );
};

export default UsersModule;
