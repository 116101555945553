import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Collapse, IconButton, Link } from "@material-ui/core";
import { alpha } from "@material-ui/core/styles";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import { Alert } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
import { requiredMessage } from "@jumbo/constants/ErrorMessages";
import CmtImage from "../../../../@coremat/CmtImage";
import { emailNotValid } from "@jumbo/constants/ErrorMessages";
import IntlMessages from "../../../utils/IntlMessages";
import { AuhMethods } from "../../../../services/auth";
import ContentLoader from "../../ContentLoader";
import { CurrentAuthMethod } from "../../../constants/AppConstants";
import AuthWrapper from "./AuthWrapper";
import { setForgetPassMailSent } from "../../../../redux/actions/Auth";
import { isValidEmail } from "@jumbo/utils/commonHelper";
const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    [theme.breakpoints.up("md")]: {
      width: "50%",
      order: 2
    }
  },
  authLogo: {
    maxWidth: "70px",
    width: "5rem"
  },
  authContent: {
    padding: 30,
    [theme.breakpoints.up("md")]: {
      order: 1,
      width: props => (props.variant === "default" ? "50%" : "100%")
    },
    [theme.breakpoints.up("xl")]: {
      padding: 50
    }
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary
  },
  textFieldRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: alpha(theme.palette.common.dark, 0.12)
    },
    "& .Mui-error": {
      color: "red"
    },
    "& .MuiFormHelperText-root": {
      color: "red"
    }
  },
  alertRoot: {
    marginBottom: 10
  }
}));

//variant = 'default', 'standard', 'bgColor'
const ForgotPassword = ({
  method = CurrentAuthMethod,
  variant = "default",
  wrapperVariant = "default"
}) => {
  const { send_forget_password_email } = useSelector(({ auth }) => auth);
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const dispatch = useDispatch();
  const classes = useStyles({ variant });
  const history = useHistory();

  useEffect(() => {
    let timeOutStopper = null;
    if (send_forget_password_email) {
      setOpen(true);

      timeOutStopper = setTimeout(() => {
        dispatch(setForgetPassMailSent(false));
        history.push("/");
      }, 1500);
    }

    return () => {
      if (timeOutStopper) clearTimeout(timeOutStopper);
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [send_forget_password_email]);

  const handelonKeyDown = e => {
    if (e.keyCode === 13) {
      //passwordFeild.current.focus();
      Validation();
    }
  };

  const Validation = () => {
    if (email === "") {
      setEmailError(requiredMessage);
    } else if (isValidEmail(email)) {
      setEmailError("");
      dispatch(AuhMethods[method].onForgotPassword({ email }));
    } else {
      setEmailError(emailNotValid);
    }
  };

  return (
    <AuthWrapper variant={wrapperVariant}>
      {variant === "default" ? (
        <div className={classes.authThumb}>
          <CmtImage
            src={process.env.PUBLIC_URL + "/images/auth/forgot-img.png"}
          />
        </div>
      ) : null}
      <div className={classes.authContent}>
        <div className={"mb-7"}>
          <CmtImage
            className={classes.authLogo}
            src={process.env.PUBLIC_URL + "/logo.png"}
          />
        </div>
        <Typography component="div" variant="h1" className={classes.titleRoot}>
          Forgot Password
        </Typography>
        <Collapse in={open}>
          <Alert
            variant="outlined"
            severity="success"
            className={classes.alertRoot}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            A mail has been sent on your email address with reset password link.
          </Alert>
        </Collapse>
        <form>
          <div className={"mb-5"}>
            <TextField
              label={<IntlMessages id="appModule.email" />}
              fullWidth
              onChange={event => {
                setEmail(event.target.value);

                setEmailError("");
              }}
              onKeyDown={handelonKeyDown}
              defaultValue={email}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
            />
            <span style={{ color: "red" }}>{emailError}</span>
          </div>

          <div className={"mb-5"}>
            <Button onClick={Validation} variant="contained" color="primary">
              <IntlMessages id="appModule.resetPassword" />
            </Button>
          </div>

          <div>
            <Typography>
              To go back to login page
              <span className={"ml-2"}>
                <Link href="#" color="secondary">
                  click here.
                </Link>
              </span>
            </Typography>
          </div>
        </form>
        <ContentLoader />
      </div>
    </AuthWrapper>
  );
};

export default ForgotPassword;
