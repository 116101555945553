export const requiredMessage = "This field is required!";
export const emailNotValid = "Email is not valid!";
export const passwordMisMatch =
  "New Password and Confirm Password did not match!";
export const passwordRulesMisMatch =
  "Password does not follow under written rules!";
export const phoneOnlyNumericals = "Phone no. is invalid!";
export const noSpaceMessage = "No spaces allowed!";
export const lettersOnlyMessage = "Only letters allowed!";
export const alphaNumaricMessage = "Only letters and number allowed!";
export const onlyNumberNotAllow = "Only numbers not allowed!";
