import { alpha, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: "relative",
    "& .MuiDialog-paperWidthSm": {
      width: "100%",
      maxWidth: "1000px"
    }
  },

  serviceTypeView: {
    marginLeft: 5,
    color: theme.palette.text.primary,
    fontSize: 14,
    letterSpacing: 0.25,
    textOverflow: "ellipsis",
    maxWidth: "350px",
    // display: 'block',
    overflow: "hidden",
    whiteSpace: "nowrap"
  },

  userInfoRoot: {
    borderBottom: `1px solid ${theme.palette.borderColor.main}`,
    padding: "20px 24px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row"
    }
  },
  avatarView: {
    [theme.breakpoints.down("sm")]: {
      "& .Cmt-avatar-size": {
        width: 40,
        height: 40
      }
    }
  },
  titleRoot: {
    // fontSize: 16,
    fontSize: 14,
    letterSpacing: 0.25,
    textOverflow: "ellipsis",
    maxWidth: "200px",
    display: "block",
    overflow: "hidden",
    whiteSpace: "nowrap",
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.up("md")]: {
      fontSize: 18
    }
  },
  titleRoot1: {
    fontWeight: "600",
    whiteSpace: "nowrap",
    "&.MuiTypography-body1": {
      fontSize: 14
    }
  },
  titleRoot2: {
    fontWeight: "300",
    marginLeft: "5px",
    fontSize: 14,
    // letterSpacing: 0.25,
    textOverflow: "ellipsis",
    maxWidth: "350px",
    // display: 'flex',
    overflow: "hidden",
    whiteSpace: "nowrap"
  },
  subTitleRoot: {
    fontSize: 12,
    letterSpacing: 0.4,
    color: theme.palette.text.secondary
  },
  labelRoot: {
    backgroundColor: alpha(theme.palette.common.dark, 0.05),
    color: theme.palette.text.disabled,
    padding: "4px 10px",
    borderRadius: 4,
    textTransform: "capitalize"
  },
  boxGrid: {
    fontSize: 14,
    letterSpacing: 0.25,
    textOverflow: "ellipsis",
    maxWidth: "400px",
    display: "block",
    overflow: "hidden",
    whiteSpace: "nowrap"
  }
}));

export default useStyles;
