// FM Provider
export const userList = "fm-provider/user-management/fm-user-list";
export const addUser = "fm-provider/user-management/fm-user-add";
export const updateFMProvider =
  "fm-provider/user-management/fm-user-status-update";
export const updateUserManagement =
  "fm-provider/user-management/fm-user-update";

// Service Activities
export const listServiceActivities =
  "fm-provider/settings/fm-service-activity/list";
export const addServiceActivities =
  "fm-provider/settings/fm-service-activity/add";
export const updateServiceActivities =
  "fm-provider/settings/fm-service-activity/status-update";
export const updateServiceActivitiesDetails =
  "fm-provider/settings/fm-service-activity/update/";

// Items List Management
export const listItems = "tenant/service-request/list";
export const addItems = "fm-provider/fm-items/add";
export const updateItems = "fm-provider/fm-items/status-update";
export const updateItemsDetails = "fm-provider/fm-items/update";
export const listitemsdetails = "fm-provider/fm-items/";
export const tenantDetails = "tenant/service-request/details/";
export const confirmCompletion = "tenant/service-request/confirm-completion";
export const raiseDispute = "tenant/service-request/raise-dispute";
export const resolveDispute = "tenant/service-request/resolve-dispute/";

// Issue Management
export const listIssue = "fm-provider/settings/fm-issue/list";
export const addIssue = "fm-provider/settings/fm-issue/add";
export const updateIssue = "fm-provider/settings/fm-issue/update/";
export const statusUpdate = "fm-provider/settings/fm-issue/status-update";

// Service Type
export const listServiceType = "/fm-provider/settings/service-type-list";
export const addServiceType = "/service-type/add";
export const updateServiceTypeStatus =
  "/fm-provider/settings/service-type-status-update";

// Sub Admin Management
export const listSubAdmin = "/sub-admin/list";
export const addSubAdmin = "/sub-admin/add";
export const updateSubAdmin = "/sub-admin/status-change";

// content page manager
export const listCMS = "cms/details/";
export const addCMS = "cms/update/";
export const AboutUsSlug = "about_us";
export const PrivacyPolicySlug = "privacy_policy";
export const TermAndConditionSlug = "terms_&_conds";
export const FAQSlug = "faq";
export const DashboardApi = "dashboard/vcg-admin";

// setting
export const virsionList = "/version/list";
export const pushVirsionList = "/version/create";

// Contact Us Query
export const listContactUsQuery = "/contact-us/list";
export const detailsContactUsQuery = "/contact-us/details/";
export const statusContactUsQuery = "/contact-us/close-request/";
export const addContactUs = "/contact-us/add/";
// Customization
export const updateCustomizationApi =
  "/fm-provider/settings/add-update-customize";
export const getCustomizationList = "/fm-provider/settings/view-customize";

// // Vendor Management
// export const vendorList ='/fm-provider/vender/list'

// Assign Items
export const AssignItemsList = "/fm-provider/fm-items/assigned-items-list";
export const GetAssignItemList = "/fm-provider/fm-items/available-items-list";
export const AddAssignItemList = "/fm-provider/fm-items/assign-items";
export const DetailsAssignItem =
  "/fm-provider/fm-items/assigned-item-view?timestamp=";

// Vendor Management
export const vendorList = "/fm-provider/vendor/list";
export const addVendor = "/fm-provider/vendor/add";
export const updateVendor = "/fm-provider/vendor/update";
export const statusUpdateVendor = "/fm-provider/vendor/status-update";
export const vendorDetail = "/fm-provider/vendor/details/";
// Return Items
export const ReturnItemList = "/fm-provider/fm-items/return-items-list";
export const AddReturnItem =
  "/fm-provider/fm-items/available-return-items-list";
export const AddReturnItemData = "/fm-provider/fm-items/return-items";
export const DetailsReturnItem =
  "/fm-provider/fm-items/return-item-view?timestamp=";

// Planned Maintenance module

// PlannedMaintenance
export const PlannedMaintenanceList = "/fm-provider/planned-maintenance/list";
export const PlannedMaintenanceStatus =
  "/fm-provider/planned-maintenance/status-change";
export const PlannedMaintenanceUpdate =
  "/fm-provider/planned-maintenance/update/";
export const PlannedMaintenanceDetails =
  "/fm-provider/planned-maintenance/view/";
export const PlannedMaintenanceAdd = "/fm-provider/planned-maintenance/add";

export const contractorListVendor = "/fm-provider/contractor/list";
export const contractorApproved = "/fm-provider/contractor/approved-status/";
export const contractorStatusUpdate = "/fm-provider/contractor/status-update/";
// Location
export const locationList = "locations/list";
export const locationAdd = "locations/add";
export const loctionDetail = "/locations/details/";
export const locationUpdate = "/locations/update/";
export const locationStatus = "/locations/status-change";

// Location(Items)
export const listItem = "/locations/items/warehouse-item-list/";
export const addItem = "/locations/items/add";
export const updateItem = "/locations/items/update";
export const deleteItem = "/locations/items/delete/";

// Tenent user
export const tenantList = "/locations/tenant/list/";
export const tenantAdd = "/locations/tenant/add/";
export const tenantStatus = "/locations/tenant/status-change";
export const tenantUpdate = "/locations/tenant/update/";

// Location :  Assigned User
export const assignedListLoaction = "/locations/assigned-users/";
export const assignedAddLocation = "/locations/assign-user/";

// Location: Assest
export const assestListLoaction = "/locations/asset/list/";
export const assestDetails = "/locations/asset/details/";
export const assestAddLoaction = "/locations/asset/add/";
export const assestUpdateLoaction = "/locations/asset/update/";
export const assestStatusUpdateLoaction = "/locations/asset/status-change";
export const assestTypeListApi = "fm-provider/asset-type-list/";
// Location SelfHelp content
export const selfHelpContentList = "/locations/asset/self-help-content/list/";
export const selfHelpContentAdd = "/locations/asset/self-help-content/add/";
export const selfHelpContentStatus =
  "/locations/asset/self-help-content/status-change";
//BudgetManagement
export const budgetList = "/fm-provider/budget/list";
export const updateBudget = "/fm-provider/budget/update";

// Locations Induction
export const inductionList = "/locations/induction-content/list/";
export const inductionAdd = "/locations/induction-content/add/";
export const inductionStatus = "/locations/induction-content/status-change";
// Dashboard
export const dashboard = "/fm-provider/dashboard";

// Service Request module
export const listservicerequest = "/fm-provider/service-request/list";

// create service request
export const callCenterServiceRequestList =
  "/call-center-user/service-request-list";
export const callCenterServiceRequestDetail =
  "/call-center-user/service-request-details/";
export const callCenterServiceRequestTable =
  "/call-center-user/asset-table-list/";
export const addCallCenterServiceRequest = "/tenant/service-request/add";
export const callCenterSiteList =
  "/call-center-user/service-request/get-site-list";

// selfHelpList
export const selfHelpList = "/tenant/service-request/self-help-content/list?";
