import {
  ADD_ITEMSLIST,
  DELETE_BULK_ITEMSLIST,
  DELETE_ITEMSLIST,
  EDIT_ITEMSLIST,
  GET_ITEMSLIST,
  GET_TENANT_DETAILS,
  SET_TENANT_DETAILS,
  GET_ITEMSLISTDETAILS,
  GET_ASSETLOCATION,
  GET_ASSET_DETAILS
} from "../../@jumbo/constants/ActionTypes";

const INIT_STATE = {
  users: [],
  currentUser: null,
  details: null,
  asset_details: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ITEMSLIST: {
      return {
        ...state,
        users: action.payload
      };
    }

    case GET_TENANT_DETAILS: {
      return {
        ...state,
        details: action.payload
      };
    }

    case GET_ASSETLOCATION: {
      return {
        ...state,
        details: action.payload
      };
    }

    case GET_ASSET_DETAILS: {
      return {
        ...state,
        asset_details: action.payload
      };
    }

    case SET_TENANT_DETAILS: {
      return {
        ...state,
        currentUser: action.payload
      };
    }
    case ADD_ITEMSLIST: {
      return {
        ...state,
        users: [action.payload, ...state.users]
      };
    }
    case EDIT_ITEMSLIST: {
      return {
        ...state,
        users: state.users.map(user =>
          user.id === action.payload.id ? action.payload : user
        )
      };
    }
    case DELETE_ITEMSLIST: {
      return {
        ...state,
        users: state.users.filter(user => user.id !== action.payload)
      };
    }
    case DELETE_BULK_ITEMSLIST: {
      return {
        ...state,
        users: state.users.filter(user => !action.payload.includes(user.id))
      };
    }
    default:
      return state;
  }
};
