import {
  GET_ASSETTYPE,
  GET_CALLCENTER_SERVICE_REQUEST_LIST,
  GET_CALL_CENTER_ASSET_TABLE_LIST,
  GET_CALL_CENTER_ISSUE_LIST,
  GET_CALL_CENTER_SERVICE_TYPE_LIST,
  GET_CALL_CENTER_SITE_LIST,
  GET_CERTIFICATELIST,
  GET_DOCUMENTS,
  GET_VIDEOS,
  SET_CURRENT_CALLCENTER_SERVICE_REQUEST,
  SET_CURRENT_CERTIFICATE
} from "../../@jumbo/constants/ActionTypes";

const INIT_STATE = {
  users: [],
  currentUser: null,
  siteList: [],
  serviceTypeList: null,
  issueList: [],
  assetTable: [],
  assetTypeList: [],
  Documents: [],
  Videos: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CALLCENTER_SERVICE_REQUEST_LIST: {
      return {
        ...state,
        users: action.payload
      };
    }
    case SET_CURRENT_CALLCENTER_SERVICE_REQUEST: {
      return {
        ...state,
        currentUser: action.payload
      };
    }
    case GET_CALL_CENTER_SITE_LIST: {
      return {
        ...state,
        siteList: action.payload
      };
    }
    case GET_CALL_CENTER_SERVICE_TYPE_LIST: {
      return {
        ...state,
        serviceTypeList: action.payload
      };
    }
    case GET_CALL_CENTER_ISSUE_LIST: {
      return {
        ...state,
        issueList: action.payload
      };
    }
    case GET_CALL_CENTER_ASSET_TABLE_LIST: {
      return {
        ...state,
        assetTable: action.payload
      };
    }
    case GET_ASSETTYPE: {
      return {
        ...state,
        assetTypeList: action.payload
      };
    }
    case GET_DOCUMENTS: {
      return {
        ...state,
        Documents: action.payload
      };
    }
    case GET_VIDEOS: {
      return {
        ...state,
        Videos: action.payload
      };
    }
    default:
      return state;
  }
};
